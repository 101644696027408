class sessionStorageHandler {
    constructor() {
        this._prefix = 'BANDYER_CHAT_WIDGET_';
    }

    getItem(key = null) {
        if (key) {
            sessionStorage.getItem(`${this._prefix}${key}`);
        }
        return null;
    }

    setItem(key = null, value) {
        if (key) {
            sessionStorage.setItem(`${this._prefix}${key}`, value);
        }
    }

    removeItem(key = null) {
        if (key) {
            sessionStorage.removeItem(`${this._prefix}${key}`);
        }
    }

    clear() {
        sessionStorage.clear();
    }
}

export default sessionStorageHandler;
