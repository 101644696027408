import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ChatIcon from '@material-ui/icons/Chat';
import { Fab, Tooltip } from '@material-ui/core';
import { Map, List } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { buildChannelUniqueName } from '../../../../../../../../helpers/utils';
import { CHANNELS } from '../../../../../../../../constants';
import Style from '../../style.scss';

class BackToChat extends PureComponent {
    getNotification() {
        const { call, channels, localUser } = this.props;
        let notification = 0;
        const callParticipants = call.get('callParticipants');
        let callParticipant = '';
        const callInitiator = call
            .get('callInitiator')
            .get('user')
            .get('userAlias');
        callParticipants.forEach(p => {
            if (p.get('user').get('userAlias') !== callInitiator) {
                callParticipant = p.get('user').get('userAlias');
            }
        });
        const member = localUser.get('userAlias') !== callInitiator ? callInitiator : callParticipant;
        const uniqueName = buildChannelUniqueName([localUser.get('userAlias'), member]);
        channels.some(c => {
            if (c.get('uniqueName') === uniqueName) {
                notification = c.get('unreadMessage');
                return true;
            }
        });
        const callTopContainer = document.getElementById(Style['call-top-container']);
        if (callTopContainer && notification === 1) {
            callTopContainer.className = Style['call-top-container'].concat(' ', Style['show-hide']);
        }
        return notification;
    }

    backToChat = async () => {
        const { call, addChannel, localUser, selectChannel, changeView, channels } = this.props;

        const callParticipants = call.get('callParticipants');
        let callParticipant = '';
        const callInitiator = call
            .get('callInitiator')
            .get('user')
            .get('userAlias');
        // retrive the alias of the other user from all the partecipants(that have the callInitiator inside)
        callParticipants.forEach(p => {
            if (p.get('user').get('userAlias') !== callInitiator) {
                callParticipant = p.get('user').get('userAlias');
            }
        });
        /* bring the alias of the user with which to start a conversation */
        const member = localUser.get('userAlias') != callInitiator ? callInitiator : callParticipant;
        const uniqueName = buildChannelUniqueName([localUser.get('userAlias'), member]);
        try {
            /* try to addChannel, if the call is programmatically created, maybe there is not a channel */
            let exists = false;
            channels.forEach(c => {
                if (c.get('uniqueName') === uniqueName) {
                    exists = true;
                }
            });
            if (!exists) {
                await addChannel(member);
            }
            selectChannel(uniqueName, callParticipants); // now the channel exist, back to them
        } catch (e) {
            const error = e.message;
            switch (error) {
                case 'The chat was already created':
                    selectChannel(uniqueName, callParticipants);
                    break;
                case 'The user passed is not valid':
                    changeView(CHANNELS);
                    break;
                default:
                    changeView(CHANNELS);
            }
        }
    };

    renderbackToChatIcon = () => {
        const { headerStyle, size } = this.props;
        const ToolTipBackToChat = <FormattedMessage id="Call.ToolTipBackToChat" defaultMessage="Back to chat" />;
        return (
            <Tooltip title={ToolTipBackToChat}>
                <Fab
                    size={size}
                    style={{
                        background: headerStyle.get('button').get('background'),
                        color: headerStyle.get('button').get('color'),
                        overflow: 'visible'
                    }}
                    className={Style.callTopButtons}
                    onClick={this.backToChat}
                >
                    <ChatIcon style={{ color: headerStyle.get('button').get('color') }} />
                    {!!this.getNotification() && (
                        <span className={Style['notification-badge']}>{this.getNotification()}</span>
                    )}
                </Fab>
            </Tooltip>
        );
    };

    render() {
        return this.renderbackToChatIcon();
    }
}

BackToChat.propTypes = {
    headerStyle: PropTypes.instanceOf(Map),
    call: PropTypes.instanceOf(Map),
    addChannel: PropTypes.func,
    localUser: PropTypes.instanceOf(Map),
    selectChannel: PropTypes.func,
    changeView: PropTypes.func,
    channels: PropTypes.instanceOf(List),
    notification: PropTypes.number
};

BackToChat.defaultProps = {
    headerStyle: Map({}),
    call: Map({}),
    addChannel: null,
    localUser: Map({}),
    selectChannel: null,
    changeView: null,
    channels: List([]),
    notification: 0
};

export default BackToChat;
