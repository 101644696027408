import React, { PureComponent } from 'react';
import PhoneInTalk from '@material-ui/icons/PhoneInTalk';
import CallEnd from '@material-ui/icons/CallEnd';
import { Fab } from '@material-ui/core';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import BackToChat from '../call/components/BackToChat';
import Style from './style.scss';

class CallWindowComponent extends PureComponent {
    render() {
        const {
            callStyle,
            focusWindowCall,
            closeWindowCall,
            call,
            headerStyle,
            addChannel,
            localUser,
            selectChannel,
            changeView,
            channels,
            notification,
            chatInit
        } = this.props;
        const CallWindow = (
            <FormattedMessage id="Call.CallWindow" defaultMessage="The call is in progress on another page" />
        );
        return (
            <div
                className={Style['call-window-container']}
                style={{
                    color: callStyle.get('color'),
                    background: callStyle.get('background')
                }}
            >
                <div className={Style.text}>{CallWindow}</div>
                <div className={Style.actions}>
                    {chatInit && (
                        <BackToChat
                            size="large"
                            call={call}
                            headerStyle={headerStyle}
                            addChannel={addChannel}
                            localUser={localUser}
                            selectChannel={selectChannel}
                            changeView={changeView}
                            channels={channels}
                            notification={notification}
                        />
                    )}
                    <Fab onClick={focusWindowCall} className={Style['focus-call']}>
                        <PhoneInTalk />
                    </Fab>
                    <Fab className={Style['reject-call']} onClick={closeWindowCall}>
                        <CallEnd />
                    </Fab>
                </div>
            </div>
        );
    }
}

CallWindowComponent.propTypes = {
    channels: PropTypes.instanceOf(List),
    notification: PropTypes.number,
    chatInit: PropTypes.bool
};

CallWindowComponent.defaultProps = {
    channels: List([]),
    notification: 0,
    chatInit: true
};

export default CallWindowComponent;
