import Logger from '../../logger/index';
import Util from './util';
import {
    MESSAGES_TYPES,
    MESSAGE_SENDER,
    MESSAGE_RECEIVED,
    MESSAGE_SENT,
    MESSAGES_STATUS,
    CALL,
    CALL_WINDOW
} from '../../constants';
import { parseAttributesMessage, buildPlatformInfo, buildChannelUniqueName } from '../../helpers/utils';

import { NO_INPUT_MESSAGE } from '../../helpers/errorHandler';

import {
    addResponseMessage,
    updateChannelLastMessage,
    updateMessageSent,
    updateMessageReceived,
    setUnreadMessagesPerChannel,
    updateUnreadMessagePerChannel,
    addUserMessage
} from '../../store/actions/dispatcher';
import store from '../../store/store';

class Message {
    constructor(client) {
        this._client = client;
        this._Util = new Util(this._client);
    }

    // MESSAGE EVENTS HANDLER

    handleMessageAdded(message) {
        Logger.debug('_handleMessageAdded', message);
        switch (parseAttributesMessage(message.state.attributes)) {
            case MESSAGES_TYPES.TEXT:
            default:
                this._handleTextMessage(message);
        }
    }

    /**
     * This fn sendMessage in the channel selected
     * @param uniqueName of the channel
     * @param message The message body for text message, FormData or MediaOptions for media content
     * @returns {*}
     */
    sendMessage(uniqueName, message, options = {}) {
        if (!message) {
            return Promise.reject(new Error(NO_INPUT_MESSAGE));
        }
        const messageOptions = options;
        messageOptions.platform = buildPlatformInfo();
        return new Promise((resolve, reject) =>
            this._client._twilioChatClient.channels[uniqueName]
                .sendMessage(message, messageOptions)
                .then(idxSent =>
                    this._Util.setAllReadMessages(uniqueName).then(() => resolve({ message, index: idxSent }))
                )
                .catch(err => reject(err))
        );
    }

    /**
     * This fn message handles the text message received and calls different methods to dispatch actions in the store.
     * Let's explore the cases:
     * The message is from another user:
     *     1 -  the function call the updateChannelLastMessage to update last message in the store.
     *     2 - if the channel selected by the user is the same of the message received, the function call. Otherwise,
     *     it calls the setUnreadMessagesPerChannel fn which sets the unread message in the store.
     * the addResponseMessage to append the message in the chat and set all messages read
     * by calling the setAllReadMessages fn
     * @param
     * @private
     */
    _handleTextMessage(message) {
        // Messaggio ricevuto
        const { behavior } = store.getState();
        const view = behavior.get('view');
        const call = behavior.get('call');
        let callUniqueName = '';
        //build channel uniquue name from call, used in case of send notification in call view
        if (!call.isEmpty()) {
            const localUser = behavior.get('localUser');
            const callParticipants = call.get('callParticipants');
            let callParticipant = '';
            const callInitiator = call
                .get('callInitiator')
                .get('user')
                .get('userAlias');
            callParticipants.forEach(p => {
                if (p.get('user').get('userAlias') !== callInitiator) {
                    callParticipant = p.get('user').get('userAlias');
                }
            });
            const member = localUser.get('userAlias') != callInitiator ? callInitiator : callParticipant;
            callUniqueName = buildChannelUniqueName([localUser.get('userAlias'), member]);
        }

        if (message.state.author !== this._client.localUserAlias) {
            // todo da rivedere tutta la funzione di handle message
            if (
                this._client._twilioChatClient.currentChannel &&
                this._client._twilioChatClient.currentChannel.uniqueName === message.channel.state.uniqueName &&
                view !== CALL &&
                view !== CALL_WINDOW
            ) {
                this._Util.setAllReadMessages(message.channel.state.uniqueName);
                Message.dispatchUnreadMessages(message.channel.state.uniqueName, 0);
            } else if (
                this._client._twilioChatClient.currentChannel == null ||
                (this._client._twilioChatClient.currentChannel &&
                    this._client._twilioChatClient.currentChannel.uniqueName !== message.channel.state.uniqueName)
            ) {
                if (message.channel.state.lastConsumedMessageIndex) {
                    Message.dispatchUnreadMessages(
                        message.channel.state.uniqueName,
                        Math.abs(message.state.index - message.channel.state.lastConsumedMessageIndex)
                    );
                } else {
                    Message.dispatchUnreadMessages(message.channel.state.uniqueName, Math.abs(message.state.index - 1));
                }
            }
            if (
                !call.isEmpty() &&
                callUniqueName === message.channel.state.uniqueName &&
                (view === CALL || view === CALL_WINDOW)
            ) {
                //send notification as unread messages in call view(only if the messages is for the user in call)
                Message.dispatchUnreadMessages(
                    message.channel.state.uniqueName,
                    Math.abs(message.state.index - message.channel.state.lastConsumedMessageIndex)
                );
            }
            const messageObj = Util.buildMessageObj(message, MESSAGE_SENDER.RESPONSE);
            addResponseMessage(message.channel.state.uniqueName, messageObj);
            this.dispatchActivitiesMessageReceived(message);
        } else if (message.state.author === this._client.localUserAlias) {
            this.dispatchActivitiesMessageSent(message);
        }
    }

    /**
     * This fn handle the dispatch of some activities like emit the event to client object, update messageReceived
     * data structure in store and update channel last message data structure in store.
     * @param message
     */
    dispatchActivitiesMessageReceived(message) {
        const messageToEmit = Util.buildMessageEventObj(message, this._client.localUserAlias);
        updateMessageReceived(messageToEmit);
        updateChannelLastMessage(
            message.channel.state.uniqueName,
            Object.assign(
                {},
                {
                    message: message.state.body,
                    timestamp: message.state.timestamp,
                    author: message.state.author
                }
            )
        );
        this._client.emit(MESSAGE_RECEIVED, messageToEmit);
    }

    dispatchActivitiesMessageSent(message) {
        const messageToEmit = Util.buildMessageEventObj(message, this._client.localUserAlias);
        addUserMessage(message.channel.state.uniqueName, {
            text: message.body,
            sender: MESSAGE_SENDER.CLIENT,
            readTimestamp: null,
            timestamp: message.timestamp,
            status: MESSAGES_STATUS.SENT
        });
        updateChannelLastMessage(
            message.channel.state.uniqueName,
            Object.assign(
                {},
                {
                    message: message.state.body,
                    timestamp: message.state.timestamp,
                    author: message.state.author
                }
            )
        );
        updateMessageSent(messageToEmit);
        this._client.emit(MESSAGE_SENT, messageToEmit);
    }

    static dispatchUnreadMessages(uniqueName, unreadMessage) {
        updateUnreadMessagePerChannel(uniqueName, unreadMessage);
        setUnreadMessagesPerChannel(uniqueName, unreadMessage);
    }
}

export default Message;
