// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loader-hidden{display:none}.loader-active{display:block}.Bandyer-message{margin:10px;display:flex;word-break:break-word;flex-wrap:wrap}.Bandyer-client{border-radius:10px;padding:15px;max-width:215px;min-width:120px;text-align:left;display:flex;flex-direction:column;font-size:14px;margin-left:auto}.Bandyer-response{border-radius:10px;padding:15px;max-width:215px;min-width:120px;text-align:left;display:flex;flex-direction:column;font-size:14px}.Bandyer-client .Bandyer-timestamp,.Bandyer-response .Bandyer-timestamp{font-size:9px;align-self:flex-end;margin-top:4px}.Bandyer-message{font-size:14px}.Bandyer-message-text p{margin:0}.Bandyer-avatar{width:40px;height:40px;border-radius:100%;margin-right:10px}@media only screen and (max-width: 768px), screen and (max-height: 450px){.Bandyer-message{font-size:17px}}\n", ""]);
// Exports
exports.locals = {
	"bDarkBlue": "#0c2e41",
	"bBlue": "#29b6f6",
	"bLightBlue": "#0277bd",
	"bOrange": "#ff6d00",
	"bLightOrange": "#ffa726",
	"bWhite": "#fff",
	"bBlack": "#000",
	"bGrey3": "#b5b5b5",
	"bGreyMessage": "#dadada",
	"bGreen": "#42af73",
	"blueBandyer": "#0C2E41",
	"blueMessage": "#448aff",
	"message": "Bandyer-message",
	"client": "Bandyer-client",
	"response": "Bandyer-response",
	"timestamp": "Bandyer-timestamp",
	"message-text": "Bandyer-message-text",
	"avatar": "Bandyer-avatar"
};
module.exports = exports;
