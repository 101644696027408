import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions, Button, Typography, IconButton } from '@material-ui/core';

import { red, blue } from '@material-ui/core/colors';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';
import { GUM_NOT_ALLOWED_ERROR, GUM_SECURITY_ERROR } from '../../../../../../../../constants';

createMuiTheme({
    palette: {
        primary: blue,
        secondary: red
    }
});
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const backdrop = { position: 'absolute' };

const permissionHeader = <FormattedMessage id="Permission.Header" defaultMessage="Grant permission" />;
const permissionContent = (
    <FormattedMessage id="Permission.Content" defaultMessage="Please allow permission to grant microphone access" />
);
const closeButton = <FormattedMessage id="Dialog.CloseButton" defaultMessage="Close" />;

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class RequestPermission extends PureComponent {
    componentDidMount() {
        const { firstTry } = this.props;
        this.timeout = null;
        this._ismounted = true;
        this.askPermission(firstTry);
    }

    componentDidUpdate(prevProps) {
        const { firstTry } = this.props;
        if (prevProps.firstTry !== firstTry && !firstTry) {
            this.askPermission(firstTry);
        }
    }

    componentWillUnmount() {
        this._ismounted = false;
        clearTimeout(this.timeout);
    }

    askPermission = async firstTry => {
        const { setMicrophonePermissionDenied, toggleGear, closePermission, getMediaDevices } = this.props;
        // firstTry indicate if the callComponent is already been mounted, so we are at the first get user media
        if (!firstTry) {
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(async mediaStream => {
                    if (!this._ismounted) {
                        return;
                    }
                    setMicrophonePermissionDenied(false);
                    closePermission();
                    await getMediaDevices('audio');
                    toggleGear();
                    mediaStream.getTracks().forEach(t => t.stop());
                })
                .catch(err => {
                    if (!this._ismounted) {
                        return;
                    }
                    if (err.name === GUM_NOT_ALLOWED_ERROR || err.name === GUM_SECURITY_ERROR) {
                        this.timeout = setTimeout(() => {
                            this.askPermission(false);
                        }, 1000);
                    }
                });
        }
    };

    close = () => {
        const { togglePermission } = this.props;
        togglePermission(false);
    };

    render() {
        const { showPermission, container } = this.props;
        return (
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={showPermission}
                container={container}
                style={{ position: 'absolute' }}
                onClose={this.close}
                fullWidth
                maxWidth="xl"
                BackdropProps={{ style: backdrop }}
                PaperProps={{ style: { margin: 20, maxHeight: 'inherit', width: 'auto' } }}
            >
                <DialogTitle onClose={this.close}>{permissionHeader}</DialogTitle>

                <DialogContent style={{ overflow: 'hidden' }}>{permissionContent}</DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={this.close} autoFocus>
                        {closeButton}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
RequestPermission.propTypes = {
    showPermission: PropTypes.bool,
    closePermission: PropTypes.func,
    togglePermission: PropTypes.func,
    container: PropTypes.shape({ component: PropTypes.instanceOf(React.Component) }),
    callType: PropTypes.string,
    setCameraPermissionDenied: PropTypes.func,
    setMicrophonePermissionDenied: PropTypes.func,
    toggleGear: PropTypes.func,
    selectedAudioDevice: PropTypes.string,
    firstTry: PropTypes.bool,
    getMediaDevices: PropTypes.func
};

RequestPermission.defaultProps = {
    showPermission: true,
    closePermission: null,
    togglePermission: null,
    container: null,
    callType: null,
    setCameraPermissionDenied: null,
    setMicrophonePermissionDenied: null,
    toggleGear: null,
    selectedAudioDevice: null,
    firstTry: false,
    getMediaDevices: null
};

export default RequestPermission;
