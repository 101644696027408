import React from 'react';
import { connect } from 'react-redux';
import LauncherComponent from './LauncherComponent';

const mapStateToProps = store => ({
    chatOpened: store.behavior.get('showChat'),
    notification: store.channels.filter(ch => ch.get('unreadMessage') > 0).size
});

export default connect(mapStateToProps)(LauncherComponent);
