import React, { PureComponent } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Style from './style.scss';
import { ReactComponent as BlurIcon } from '../../../../../../../../assets/icon/Blur.svg';

class Blur extends PureComponent {
    constructor(props) {
        super(props);
        const { virtualBackgroundState } = this.props;
        const active = virtualBackgroundState.type === 'blur' && virtualBackgroundState.active;
        this.state = { active, loading: false };
    }

    componentDidUpdate(prevProps) {
        const { virtualBackgroundState } = this.props;
        if (prevProps.virtualBackgroundState !== virtualBackgroundState) {
            const active = virtualBackgroundState.type === 'blur' && virtualBackgroundState.active;
            this.setState({ active, loading: false });
        }
    }

    toggleBlur = async() => {
        const { active } = this.state;
        const { toggleVirtualBackground } = this.props;
        if (!active) {
            this.setState({ loading: true });
        }
        toggleVirtualBackground('blur');
    }

    render() {
        const toolTipBlurDisabled = <FormattedMessage id="Gear.BlurDisabled" defaultMessage="Blur disabled" />;
        const toolTipBlurEnabled = <FormattedMessage id="Gear.BlurEnabled" defaultMessage="Blur enabled" />;
        const { active, loading } = this.state;
        const statusClass = active ? 'active' : 'not-active';
        const tooltip = active ? toolTipBlurEnabled : toolTipBlurDisabled;
        return (
            <Tooltip title={tooltip} placement="right">
                <IconButton id="blur" className={Style.blur.concat(' ', Style[statusClass])} onClick={this.toggleBlur}>
                    <BlurIcon className={Style['blur-icon']} />
                    {loading && <CircularProgress size={45} className={Style.progress} />}
                </IconButton>
            </Tooltip>
        );
    }
}

// eslint-disable-next-line react/forbid-prop-types
Blur.propTypes = { virtualBackgroundState: PropTypes.any, toggleVirtualBackground: PropTypes.func };

Blur.defaultProps = { virtualBackgroundState: {}, toggleVirtualBackground: null };

export default Blur;
