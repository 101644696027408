import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    Button,
    Divider,
    Typography,
    IconButton,
    Grid,
    Avatar,
    Fab,
    GridList,
    Input, SvgIcon, Tooltip, CircularProgress
} from '@material-ui/core';

import { red, blue } from '@material-ui/core/colors';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import MicOffIcon from '@material-ui/icons/MicOff';
import MicIcon from '@material-ui/icons/Mic';
import VideocamIcon from '@material-ui/icons/Videocam';
import { connect } from 'react-redux';
import AudioVisualiser from '../AudioVisualizer';
import { fetchUserImage } from '../../../../../../../../helpers/utils';
import { CALL_TYPE_AUDIO_ONLY } from '../../../../../../../../constants';
import Logger from '../../../../../../../../logger';
import {
    gearHeader,
    cameraHeader,
    unknownCamera,
    microphoneHeader,
    recordingHeader,
    noCamera,
    noMicrophone,
    unknownMicrophone,
    noCameraPermission,
    allowCamera,
    noVideo,
    noMicrophonePermission,
    allowMicrophone,
    closeButton,
    toolTipRecordingOff,
    toolTipRecordingOn,
    blurHeader,
    virtualBackgroundHeader
} from './language';
import { ReactComponent as ManualRecordingIcon } from '../../../../../../../../assets/icon/ic_bandyer_rec.svg';
import Style from './style.scss';
import { ReactComponent as VerificationImage } from '../../../../../../../../assets/icon/verified_user_white-24px.svg';
import VirtualBackground from '../VirtualBackground';
import Blur from '../Blur';
import BandyerServices from '../../../../../../../../services';

createMuiTheme({
    palette: {
        primary: blue,
        secondary: red
    }
});
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const backdrop = { position: 'absolute' };

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class Gear extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            camera: 'none',
            microphone: 'none',
            showVideo: true,
            microphoneMedia: null,
            showAudio: true,
            cameraLabel: 'none',
            microphoneLabel: 'none',
            openCamera: props.openSelectCamera,
            openMicrophone: props.openSelectMicrophone,
            virtualBackgroundState: BandyerServices.getInstance().services.call.virtualBackgroundState,
            isVirtualBackgroundSupported: BandyerServices.getInstance().services.call.isVirtualBackgroundSupported

        };
        this._L = new Logger('Gear');
    }

    componentDidMount() {
        const {
            mediaDevices,
            setCameraPermissionDenied,
            selectedVideoDevice,
            selectedAudioDevice,
            openSelectCamera,
            openSelectMicrophone
        } = this.props;
        let camera = selectedVideoDevice;
        const microphone = selectedAudioDevice;
        if (mediaDevices.every(device => (device.kind === 'videoinput' ? device.label === '' : true))) {
            setCameraPermissionDenied(true);
            camera = 'none';
        } else {
            setCameraPermissionDenied(false);
        }
        this.setState({
            camera,
            microphone,
            openCamera: openSelectCamera,
            openMicrophone: openSelectMicrophone,
            virtualBackgroundState: BandyerServices.getInstance().services.call.virtualBackgroundState
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        const { camera, microphone } = this.state;
        const { mediaStream, recordingInfo } = this.props;
        const video = document.getElementById('localCameraVideo');
        if (prevState.camera !== camera && camera === 'none') {
            mediaStream.getVideoTracks().forEach(t => t.stop());
        }
        if (prevState.microphone !== microphone && microphone === 'none') {
            mediaStream.getAudioTracks().forEach(t => t.stop());
        }
        if (prevProps.mediaStream !== mediaStream) {
            if (mediaStream && mediaStream.getVideoTracks().length) {
                if (typeof window.attachMediaStream !== 'undefined') {
                    window.attachMediaStream(video, mediaStream);
                } else {
                    video.srcObject = mediaStream; // Fallback to legacy if Adapter not present
                }
                this.setState({ cameraLabel: mediaStream.getVideoTracks()[0].label });
            }

            if (mediaStream && mediaStream.active && mediaStream.getAudioTracks().length) {
                this.setState({ microphoneMedia: mediaStream, microphoneLabel: mediaStream.getAudioTracks()[0].label });
            }
        }
        if (prevState.camera !== camera && camera !== 'none') {
            if (typeof window.attachMediaStream !== 'undefined') {
                window.attachMediaStream(video, mediaStream);
            } else {
                video.srcObject = mediaStream; // Fallback to legacy if Adapter not present
            }
        }
        if (prevState.microphone !== microphone && microphone !== 'none') {
            if (mediaStream && mediaStream.active) {
                this.setState({ microphoneMedia: mediaStream });
            }
        }
    }

    setCamera = async(event) => {
        const { selectVideoDevice, changeDeviceSource, callAlias } = this.props;
        const { microphone } = this.state;
        selectVideoDevice(event.target.value);
        changeDeviceSource(callAlias, microphone, event.target.value);
        this.setState({ camera: event.target.value, showVideo: true });
    };

    setMicrophone = (event) => {
        const { selectAudioDevice, changeDeviceSource, callAlias } = this.props;
        const { camera } = this.state;
        selectAudioDevice(event.target.value);
        changeDeviceSource(callAlias, event.target.value, camera);
        this.setState({ microphone: event.target.value, showAudio: true });
    };

    closeWidget = () => {
        const { toggleGear } = this.props;
        toggleGear();
    };

    allowCameraPermission = async() => {
        const {
            setCameraPermissionDenied,
            mediaDevices,
            callAlias,
            selectVideoDevice,
            changeDeviceSource
        } = this.props;
        const { microphone } = this.state;
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
            const videoDevice = mediaDevices.find(device => device.kind === 'videoinput');
            selectVideoDevice(videoDevice.deviceId);
            mediaStream.getTracks().forEach(track => track.stop());
            changeDeviceSource(callAlias, microphone, videoDevice.deviceId);
            setCameraPermissionDenied(false);
            this.setState({ camera: videoDevice.deviceId });
        } catch {
            this._L.warn('No Camera permission allowed');
        }
    };

    allowMicrophonePermission = async() => {
        const {
            setMicrophonePermissionDenied,
            mediaDevices,
            callAlias,
            selectAudioDevice,
            changeDeviceSource
        } = this.props;
        const { camera } = this.state;
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const audioDevice = mediaDevices.find(device => device.label === mediaStream.getAudioTracks()[0].label);
            selectAudioDevice(audioDevice.deviceId);
            mediaStream.getTracks().forEach(track => track.stop());
            changeDeviceSource(callAlias, audioDevice.deviceId, camera);
            setMicrophonePermissionDenied(false);
            this.setState({ microphone: audioDevice.deviceId });
        } catch {
            this._L.warn('No Mic permission allowed');
        }
    };

    openDevices = async(select) => {
        const { getMediaDevices } = this.props;
        await getMediaDevices(select);
        select === 'video' ? this.setState({ openCamera: true }) : this.setState({ openMicrophone: true });
    };

    closeDevices = (select) => {
        select === 'video' ? this.setState({ openCamera: false }) : this.setState({ openMicrophone: false });
    };

    toggleVirtualBackground = async(type, virtualBackground = null) => {
        const virtualBackgroundState = await BandyerServices.getInstance().services.call.toggleVirtualBackground(type, virtualBackground);
        this.setState({ virtualBackgroundState });
    }

    isGetDisplayMediaSupported = () => {
        if (
            typeof navigator !== 'undefined'
            && 'mediaDevices' in navigator
            && 'getDisplayMedia' in navigator.mediaDevices
        ) {
            return true;
        }
        if (typeof navigator !== 'undefined' && 'getDisplayMedia' in navigator) {
            return true;
        }
        return false;
    };

    isMobileDevice = () => {
        if (window.DetectRTC.isMobileDevice) {
            return true;
        }
        return window.DetectRTC.browser.name === 'Safari' ? !this.isGetDisplayMediaSupported() : false;
    };


    render() {
        const {
            showGear,
            container,
            mediaDevices,
            localUser,
            callType,
            cameraPermissionDenied,
            microphonePermissionDenied,
            canVideo,
            audioMuted,
            localMuted,
            recordingInfo,
            isAdmin,
            handleManualRecording
        } = this.props;
        const {
            showVideo,
            showAudio,
            camera,
            microphone,
            microphoneMedia,
            cameraLabel,
            microphoneLabel,
            openCamera,
            openMicrophone,
            virtualBackgroundState,
            isVirtualBackgroundSupported
        } = this.state;

        const recordingTooltip = recordingInfo.recording ? toolTipRecordingOn : toolTipRecordingOff;
        const isSafari = window.DetectRTC.browser.name === 'Safari';
        return (
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={showGear}
                container={container}
                style={{ position: 'absolute' }}
                onClose={this.closeWidget}
                fullWidth
                maxWidth="xl"
                BackdropProps={{ style: backdrop }}
                PaperProps={{ style: { margin: 20, maxHeight: '95%', width: '100%', maxWidth: '600px' } }}
            >
                <DialogTitle onClose={this.closeWidget}>{gearHeader}</DialogTitle>

                <DialogContent style={{ overflowY: 'scroll' }}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
                        {callType !== CALL_TYPE_AUDIO_ONLY && canVideo && (
                            <Grid item xs={7}>
                                <div className={Style['camera-selector']} style={{ width: '100%' }}>
                                    <h4>{cameraHeader}</h4>
                                    <Select
                                        id={Style.cameraSelect}
                                        value={camera}
                                        open={openCamera}
                                        onOpen={() => this.openDevices('video')}
                                        onClose={() => this.closeDevices('video')}
                                        // inputProps={{ disabled: cameraPermissionDenied }}
                                        onChange={this.setCamera}
                                        style={{ width: '100%' }}
                                        MenuProps={{ style: { zIndex: 99999999 } }}
                                    >
                                        <MenuItem value="none">{noCamera}</MenuItem>
                                        {mediaDevices.map((device, index) => {
                                            if (device.kind === 'videoinput') {
                                                switch (device.label) {
                                                    case '':
                                                        if (device.deviceId === camera) {
                                                            return (
                                                                <MenuItem key={cameraLabel} value={device.deviceId}>
                                                                    {cameraLabel}
                                                                </MenuItem>
                                                            );
                                                        }
                                                        return (
                                                            <MenuItem key={index} value={device.deviceId}>
                                                                {unknownCamera}
                                                            </MenuItem>
                                                        );
                                                    default:
                                                        return (
                                                            <MenuItem key={device.label} value={device.deviceId}>
                                                                {device.label}
                                                            </MenuItem>
                                                        );
                                                }
                                            }
                                            return null;
                                        })}
                                    </Select>
                                </div>
                            </Grid>
                        )}
                        <Grid className={Style['media-container']} item xs={5} container justify="center">
                            {callType !== CALL_TYPE_AUDIO_ONLY && canVideo && (
                                <div className={Style.localCamera} id={Style.localCamera}>
                                    {cameraPermissionDenied && (
                                        <div className={Style.permissionDenied}>
                                            <div>{noCameraPermission}</div>
                                            <Fab
                                                variant="extended"
                                                className={Style.allowButton}
                                                onClick={this.allowCameraPermission}
                                            >
                                                <VideocamIcon />
                                                {allowCamera}
                                            </Fab>
                                        </div>
                                    )}
                                    <div
                                        className={Style.localCameravideoContainer}
                                        style={
                                            !showVideo || camera === 'none' || cameraPermissionDenied || localMuted
                                                ? { display: 'none' }
                                                : { display: 'flex' }
                                        }
                                    >
                                        <video id="localCameraVideo" autoPlay muted />
                                    </div>
                                    {!showVideo && !cameraPermissionDenied && (
                                        <div className={Style.noCamera}>
                                            <p>{noVideo}</p>
                                        </div>
                                    )}
                                    {(camera === 'none' || localMuted) && !cameraPermissionDenied && (
                                        <div className={Style['avatar-container']}>
                                            {localUser.has('image') ? (
                                                <Avatar
                                                    src={fetchUserImage(localUser)}
                                                    className={Style['big-avatar']}
                                                />
                                            ) : (
                                                <div className={Style.noCamera}>
                                                    <p>{noVideo}</p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-end" spacing={4}>
                        <Grid item xs={7}>
                            <div className={Style['microphone-selector']} style={{ width: '100%' }}>
                                <h4>{microphoneHeader}</h4>
                                <Select
                                    id={Style.microphoneSelect}
                                    value={microphone}
                                    open={openMicrophone}
                                    onOpen={() => this.openDevices('audio')}
                                    onClose={() => this.closeDevices('audio')}
                                    onChange={this.setMicrophone}
                                    style={{ width: '100%' }}
                                    MenuProps={{ style: { zIndex: 99999999 } }}
                                >
                                    <MenuItem value="none">{noMicrophone}</MenuItem>
                                    {mediaDevices.map((device, index) => {
                                        if (device.kind === 'audioinput') {
                                            switch (device.label) {
                                                case '':
                                                    if (device.deviceId === microphone) {
                                                        return (
                                                            <MenuItem key={microphoneLabel} value={device.deviceId}>
                                                                {microphoneLabel}
                                                            </MenuItem>
                                                        );
                                                    }
                                                    return (
                                                        <MenuItem key={index} value={device.deviceId}>
                                                            {unknownMicrophone}
                                                        </MenuItem>
                                                    );
                                                default:
                                                    return (
                                                        <MenuItem key={device.label} value={device.deviceId}>
                                                            {device.label}
                                                        </MenuItem>
                                                    );
                                            }
                                        }
                                        return null;
                                    })}
                                </Select>
                            </div>
                        </Grid>
                        <Grid item xs={5} container justify="center">
                            {microphone !== 'none' && (
                                <AudioVisualiser
                                    key={microphone.concat(camera)}
                                    mediaStream={microphoneMedia}
                                    audioMuted={audioMuted}
                                />
                            )}
                            {microphone === 'none' && !microphonePermissionDenied && (
                                <MicOffIcon color="error" fontSize="large" />
                            )}
                            {microphonePermissionDenied && (
                                <div>
                                    <p>{noMicrophonePermission}</p>
                                    <Fab variant="extended" onClick={this.allowMicrophonePermission} color="primary">
                                        <MicIcon />
                                        {allowMicrophone}
                                    </Fab>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    { recordingInfo.recordingType === 'manual' && isAdmin && (
                        <Grid container direction="row" justify="center" alignItems="flex-end" spacing={1}>
                            <Grid item xs={12}>
                                <div className={Style['manual-recording']} style={{ width: '100%' }}>
                                    <h4>
                                        {recordingHeader}
                                    </h4>
                                    <Tooltip placement="right" title={recordingTooltip}>
                                        <IconButton onClick={() => handleManualRecording(!recordingInfo.recording)}>
                                            <ManualRecordingIcon style={recordingInfo.recording ? { fill: '#f20600' } : { fill: '#7f7f7f' }} className={Style['manual-recording-icons']} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Grid>
                        </Grid>
                    ) }
                    {/* Check if virtual background is supported and the video is displayed, also check for mobile(not active for now) */}
                    {isVirtualBackgroundSupported && showVideo && camera !== 'none' && !cameraPermissionDenied && !localMuted && !this.isMobileDevice() && !isSafari && (
                        <Grid container direction="row" justify="center" alignItems="flex-end" spacing={1}>
                            <Grid item xs={6}>
                                <div className={Style.blur} style={{ width: '100%' }}>
                                    <h4>
                                        {blurHeader}
                                    </h4>
                                    <Blur virtualBackgroundState={virtualBackgroundState} toggleVirtualBackground={this.toggleVirtualBackground} />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={Style['virtual-background']} style={{ width: '100%' }}>
                                    <h4>
                                        {virtualBackgroundHeader}
                                    </h4>
                                    <VirtualBackground virtualBackgroundState={virtualBackgroundState} toggleVirtualBackground={this.toggleVirtualBackground} />
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button color="primary" disabled={!showVideo || !showAudio} onClick={this.closeWidget} autoFocus>
                        {closeButton}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

Gear.propTypes = {
    localMuted: PropTypes.bool,
    showGear: PropTypes.bool,
    container: PropTypes.shape({ component: PropTypes.instanceOf(React.Component) }),
    toggleGear: PropTypes.func,
    getMediaDevices: PropTypes.func,
    mediaDevices: PropTypes.instanceOf(Array),
    selectedAudioDevice: PropTypes.string,
    selectedVideoDevice: PropTypes.string,
    selectAudioDevice: PropTypes.func,
    selectVideoDevice: PropTypes.func,
    changeDeviceSource: PropTypes.func,
    callAlias: PropTypes.string,
    callType: PropTypes.string,
    localUser: PropTypes.instanceOf(Map),
    cameraPermissionDenied: PropTypes.bool,
    setCameraPermissionDenied: PropTypes.func,
    microphonePermissionDenied: PropTypes.bool,
    setMicrophonePermissionDenied: PropTypes.func,
    canVideo: PropTypes.bool,
    mediaStream: PropTypes.any || PropTypes.instanceOf(window.MediaStream),
    audioMuted: PropTypes.bool,
    openSelectMicrophone: PropTypes.bool,
    openSelectCamera: PropTypes.bool,
    recordingInfo: PropTypes.exact({
        recording: PropTypes.bool,
        recordingType: PropTypes.string
    }),
    handleManualRecording: PropTypes.func,
    isAdmin: PropTypes.bool
};

Gear.defaultProps = {
    localMuted: false,
    showGear: true,
    container: null,
    toggleGear: null,
    getMediaDevices: null,
    mediaDevices: [],
    selectedAudioDevice: 'none',
    selectedVideoDevice: 'none',
    selectAudioDevice: null,
    selectVideoDevice: null,
    changeDeviceSource: null,
    callAlias: null,
    callType: null,
    localUser: null,
    cameraPermissionDenied: false,
    setCameraPermissionDenied: null,
    microphonePermissionDenied: false,
    setMicrophonePermissionDenied: null,
    canVideo: false,
    mediaStream: null,
    audioMuted: false,
    openSelectMicrophone: false,
    openSelectCamera: false,
    recordingInfo: { recording: false, recordingType: 'automatic' },
    handleManualRecording: null,
    isAdmin: false
};

export default Gear;
