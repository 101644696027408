import { Map, fromJS } from 'immutable';
import Logger from '../../logger';
import store from '../../store/store';
import { setUsersDetails } from '../../store/actions/dispatcher';
import { TIMEOUT } from '../../constants';

const events = require('events');

class UserDetailsService extends events {
    constructor(userDetailsProvider, userDetailsFormatter) {
        super();
        this._L = Logger.scope('Services - userDetailsService');
        this._userDetailsProvider = userDetailsProvider; // Provider function given during widget initialize
        this._userDetailsFormatter = userDetailsFormatter; // Formatter function given during widget initialize
    }

    static initialize(userDetailsProvider = null, userDetailsFormatter = null) {
        return new this.userDetailsService(userDetailsProvider, userDetailsFormatter);
    }

    /* Fn that call the external formatter that must return a String or in case the formatter is not setted, return the userAlias */
    userDetailsFormatter = user => {
        try {
            if (this._userDetailsFormatter) {
                const toReturn = this._userDetailsFormatter(user.toObject());
                if (!(typeof toReturn === 'string')) {
                    throw Error('InvalidType');
                }
                return toReturn;
            }
            return this._userDetailsFormatter ? this._userDetailsFormatter(user.toObject()) : user.get('userAlias');
        } catch (err) {
            switch (err.message) {
                case 'InvalidType':
                    this._L.error(
                        '[userDetailsService - userDetailsFormatter]: Your formatter logic must return a valid string'
                    );
                    break;
                default:
                    this._L.error('[userDetailsService - userDetailsFormatter]: Your formatter logic is wrong');
            }
            return user.get('userAlias');
        }
    };

    /* Fn that check the usersDetails state variable that contains the new information of the users given from outside
    If there isn't information about the users in the list, the fn ask to the outside provide the information, than in every case update the store */
    provideDetails = users => {
        const state = store.getState();
        const usersDetails = state.usersDetails.get('usersDetails');
        let displayUser = new Map({});
        const usersToFetch = [];
        users.forEach(user => {
            const userAlias = user.get('userAlias');
            if (!usersDetails.has(userAlias)) {
                // check the state
                usersToFetch.push(userAlias); // push for fetch data
                user = user.set('formattedName', this.userDetailsFormatter(user)); // value used in the ui
                displayUser = displayUser.set(userAlias, user); // use local value
            }
        });
        if (usersToFetch.length && this._userDetailsProvider) {
            // if there are users to fetch and the provider function is defined
            const timeOutPromise = new Promise(resolve => {
                setTimeout(resolve, 1200, TIMEOUT);
            }); // prepare timeout promise
            const providerPromise = this._userDetailsProvider(usersToFetch); // call the outside provider to get the users information
            Promise.race([providerPromise, timeOutPromise])
                .then(value => {
                    // make a race between promises
                    if (value !== TIMEOUT) {
                        // if the providerPromise finish before
                        const userDetailsResult = value;
                        let fetchedUsers = new Map({});
                        // convert every user from obj to map to have a object conformity
                        userDetailsResult.forEach(user => {
                            const { userAlias } = user;
                            user = fromJS(user);
                            if (!user.has('userAlias'));
                            user = user.set('userAlias', userAlias);
                            if (!user.has('image') || user.get('image') === '') {
                                // add a default image
                                const rand = Math.floor(Math.random() * 9) + 1;
                                user = user.set('image', `avatar_${rand}.jpg`);
                            }
                            user = user.set('formattedName', this.userDetailsFormatter(user));
                            fetchedUsers = fetchedUsers.set(userAlias, user); // create a new map for update the store
                        });
                        setUsersDetails(fetchedUsers); // update store with new information
                    } else {
                        this._L.warn(
                            '[userDetailsService - ProvideDetails]: Attention yout provider take too long to resolve the Promise'
                        );
                    }
                })
                .catch(() => {
                    this._L.error(
                        '[userDetailsService - ProvideDetails]: Attention your provider function must return a valid Promise'
                    );
                });
        }
        if (!displayUser.isEmpty()) {
            setUsersDetails(displayUser);
        } // add local info to the store
    };
}

export default UserDetailsService;
