// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loader-hidden{display:none}.loader-active{display:block}.Bandyer-messages-container,#Bandyer-messages{height:100%;overflow-y:scroll}.Bandyer-fetcherLoader{text-align:center;margin-top:5px}.Bandyer-fetcherLoader .Bandyer-circularProgress{color:#0C2E41}.Bandyer-arrowButton{position:absolute;right:25px;background-color:#0C2E41;color:white;z-index:3;bottom:50px;border-radius:15px;display:inline-block;width:auto;height:auto}.Bandyer-arrowButton:hover{background-color:#0C2E41}.Bandyer-notification{position:relative;display:inline;text-align:right;font-size:small;margin-left:8px;padding-left:-20px}.Bandyer-unReadMessage{text-align:center;background-color:#D3D3D3;opacity:0.8;width:100%;border-radius:10px;margin-bottom:10px;padding:2px;font-size:12px}.Bandyer-message{margin:10px;display:flex;word-break:break-word;flex-wrap:wrap}.Bandyer-avatar{width:40px;height:40px;border-radius:100%;margin-right:10px}@media only screen and (max-width: 768px), screen and (max-height: 450px){.Bandyer-message{font-size:17px}}.full-screen .Bandyer-messages-container{height:100%;max-height:none}@media only screen and (max-width: 768px), screen and (max-height: 450px){.Bandyer-messages-container{height:100%;max-height:none}}\n", ""]);
// Exports
exports.locals = {
	"bDarkBlue": "#0c2e41",
	"bBlue": "#29b6f6",
	"bLightBlue": "#0277bd",
	"bOrange": "#ff6d00",
	"bLightOrange": "#ffa726",
	"bWhite": "#fff",
	"bBlack": "#000",
	"bGrey3": "#b5b5b5",
	"bGreyMessage": "#dadada",
	"bGreen": "#42af73",
	"blueBandyer": "#0C2E41",
	"blueMessage": "#448aff",
	"messages-container": "Bandyer-messages-container",
	"messages": "Bandyer-messages",
	"fetcherLoader": "Bandyer-fetcherLoader",
	"circularProgress": "Bandyer-circularProgress",
	"arrowButton": "Bandyer-arrowButton",
	"notification": "Bandyer-notification",
	"unReadMessage": "Bandyer-unReadMessage",
	"message": "Bandyer-message",
	"avatar": "Bandyer-avatar"
};
module.exports = exports;
