import React, { PureComponent } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Style from './style.scss';
import { ReactComponent as VirtualBackgroundIcon } from '../../../../../../../../assets/icon/VirtualBackground.svg';

class VirtualBackground extends PureComponent {
    constructor(props) {
        super(props);
        const { virtualBackgroundState } = this.props;
        const active = virtualBackgroundState.type === 'image' && virtualBackgroundState.active;
        this.state = { active, loading: false };
    }

    componentDidUpdate(prevProps) {
        const { virtualBackgroundState } = this.props;
        if (prevProps.virtualBackgroundState !== virtualBackgroundState) {
            const active = virtualBackgroundState.type === 'image' && virtualBackgroundState.active;
            this.setState({ active, loading: false });
        }
    }


    toggleVirtualBackground = async() => {
        const { active } = this.state;
        const { toggleVirtualBackground, virtualBackgrounds } = this.props;
        if (!active) {
            this.setState({ loading: true });
        }
        if (virtualBackgrounds.length) {
            toggleVirtualBackground('image', virtualBackgrounds[0].imageUrl);
        } else {
            toggleVirtualBackground('image');
        }
    }

    render() {
        const toolTipVirtualBackgroundDisabled = <FormattedMessage id="Gear.VirtualBackgroundDisabled" defaultMessage="VirtualBackground disabled" />;
        const toolTipVirtualBackgroundEnabled = <FormattedMessage id="Gear.VirtualBackgroundEnabled" defaultMessage="VirtualBackground enabled" />;
        const { active, loading } = this.state;
        const statusClass = active ? 'active' : 'not-active';
        const tooltip = active ? toolTipVirtualBackgroundEnabled : toolTipVirtualBackgroundDisabled;
        return (
            <Tooltip title={tooltip} placement="right">
                <IconButton className={Style['virtual-background'].concat(' ', Style[statusClass])} onClick={this.toggleVirtualBackground}>
                    <VirtualBackgroundIcon className={Style['virtual-background-icon']} />
                    {loading && <CircularProgress size={45} className={Style.progress} />}
                </IconButton>
            </Tooltip>
        );
    }
}

// eslint-disable-next-line react/forbid-prop-types
VirtualBackground.propTypes = { virtualBackgroundState: PropTypes.any, toggleVirtualBackground: PropTypes.func, virtualBackgrounds: PropTypes.array };

VirtualBackground.defaultProps = { virtualBackgroundState: {}, toggleVirtualBackground: null, virtualBackgrounds: [] };

export default connect(store => ({ virtualBackgrounds: store.call.get('virtualBackgrounds') }))(VirtualBackground);
