const TOGGLE_CHAT = 'TOGGLE_CHAT';
const TOGGLE_FULL_SCREEN = 'TOGGLE_FULL_SCREEN';
const TOGGLE_INPUT_DISABLED = 'TOGGLE_INPUT_DISABLED';
const SET_LOCAL_USER_INFO = 'SET_LOCAL_USER_INFO';
const SET_CHAT_OPEN = 'SET_CHAT_OPEN';
const SET_CHAT_CLOSE = 'SET_CHAT_CLOSE';
const SET_FULL_SCREEN_MODE = 'SET_FULL_SCREEN_MODE';
const HIDE_CHAT = 'HIDE_CHAT';
const SET_RECORD_VALUE = 'SET_RECORD_VALUE';
const WIDGET_READY = 'WIDGET_READY';
const CHAT_IS_LOADING = 'CHAT_IS_LOADING';
const CHANGE_VIEW = 'CHANGE_VIEW';
const UPDATE_CALL = 'UPDATE_CALL';
const CHAT_HAS_ERROR = 'CHAT_HAS_ERROR';
const LOGOUT = 'LOGOUT';
const ADD_NEW_USER_MESSAGE = 'ADD_NEW_USER_MESSAGE';
const ADD_NEW_RESPONSE_MESSAGE = 'ADD_NEW_RESPONSE_MESSAGE';
const ADD_COMPONENT_MESSAGE = 'ADD_COMPONENT_MESSAGE';
const SET_MESSAGE = 'SET_MESSAGE';
const EMPTY_MESSAGE = 'EMPTY_MESSAGE';
const REMOVE_CHAT = 'REMOVE_CHAT';
const SELECT_CHANNEL = 'SELECT_CHANNEL';
const UPDATE_UNREAD_MESSAGE_PER_CHANNEL = 'UPDATE_UNREAD_MESSAGE_PER_CHANNEL';
const ADD_CHANNEL = 'ADD_CHANNEL';
const REMOVE_CHANNEL = 'REMOVE_CHANNEL';
const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE';
const SUBSCRIBED_CHANNELS_FETCHED = 'SUBSCRIBED_CHANNELS_FETCHED';
const SUBSCRIBED_CHANNELS_FETCHED_NO_RESULTS = 'SUBSCRIBED_CHANNELS_FETCHED_NO_RESULTS';
const IS_TYPING = 'IS_TYPING';
const UPDATE_MESSAGE_RECEIVED = 'UPDATE_MESSAGE_RECEIVED';
const UPDATE_MESSAGE_SENT = 'UPDATE_MESSAGE_SENT';
const CLEAR_UNREAD_MESSAGE_PER_CHANNEL = 'CLEAR_UNREAD_MESSAGE_PER_CHANNEL';
const SET_UNREAD_MESSAGE_PER_CHANNEL = 'SET_UNREAD_MESSAGE_PER_CHANNEL';
const SET_WIDGET_CALL_TYPE = 'SET_WIDGET_CALL_TYPE';
const SET_CAN_VIDEO = 'SET_CAN_VIDEO';
const SET_WIDGET_MODE = 'SET_WIDGET_MODE';
const SET_LANGUAGE = 'SET_LANGUAGE';
const SET_USER_STATUS_PER_CHANNEL = 'SET_USER_STATUS_PER_CHANNEL';
const HAVE_CHAT = 'HAVE_CHAT';
const WAS_HIDE = 'WAS_HIDE';
const SET_CHAT_INIT = 'SET_CHAT_INIT';
const SET_VIRTUAL_BACKGROUND = 'SET_VIRTUAL_BACKGROUND';

export default {
    TOGGLE_CHAT,
    TOGGLE_FULL_SCREEN,
    TOGGLE_INPUT_DISABLED,
    SET_LOCAL_USER_INFO,
    SET_CHAT_OPEN,
    SET_CHAT_CLOSE,
    SET_FULL_SCREEN_MODE,
    HIDE_CHAT,
    SET_RECORD_VALUE,
    SELECT_CHANNEL,
    CHAT_IS_LOADING,
    WIDGET_READY,
    CHANGE_VIEW,
    UPDATE_CALL,
    CHAT_HAS_ERROR,
    LOGOUT,
    ADD_COMPONENT_MESSAGE,
    ADD_NEW_RESPONSE_MESSAGE,
    ADD_NEW_USER_MESSAGE,
    SET_MESSAGE,
    EMPTY_MESSAGE,
    UPDATE_UNREAD_MESSAGE_PER_CHANNEL,
    ADD_CHANNEL,
    REMOVE_CHANNEL,
    UPDATE_LAST_MESSAGE,
    SUBSCRIBED_CHANNELS_FETCHED,
    SUBSCRIBED_CHANNELS_FETCHED_NO_RESULTS,
    IS_TYPING,
    UPDATE_MESSAGE_RECEIVED,
    UPDATE_MESSAGE_SENT,
    CLEAR_UNREAD_MESSAGE_PER_CHANNEL,
    SET_UNREAD_MESSAGE_PER_CHANNEL,
    SET_WIDGET_CALL_TYPE,
    SET_CAN_VIDEO,
    SET_WIDGET_MODE,
    SET_LANGUAGE,
    SET_USER_STATUS_PER_CHANNEL,
    REMOVE_CHAT,
    HAVE_CHAT,
    WAS_HIDE,
    SET_CHAT_INIT,
    SET_VIRTUAL_BACKGROUND
};
