import actions from './actions';
import BandyerService from '../../../services';

const { updateUsersDetails } = actions;

const provideDetails = function(users) {
    return () => {
        BandyerService.getInstance().services.userDetailsService.provideDetails(users);
        return;
    };
};

export default {
    updateUsersDetails,
    provideDetails
};
