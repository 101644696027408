import { Map } from 'immutable';
import actionTypes from './types';

const initialState = Map({
    usersDetails: new Map({})
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_USERS_DETAILS: {
            let usersDetails = new Map(state.get('usersDetails'));
            action.userDetails.forEach(u => {
                usersDetails = usersDetails.set(u.get('userAlias'), u);
            });
            return state.update('usersDetails', () => usersDetails);
        }
        default:
            return state;
    }
}
