import React, { Component } from 'react';
import { List, Map } from 'immutable';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { constants } from 'bandyersdkcommon';
import { InputBase, Button, Chip, SvgIcon, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { intlShape, injectIntl } from 'react-intl';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import Style from './style.scss';
import Header from './components/Header';
import Channel from './components/Channel';
import widgetOperations from '../../../../redux/operations';
import { ReactComponent as FunnelIcon } from '../../../../../../assets/icon/funnel2-24px.svg';

class Channels extends Component {
    constructor(props) {
        super(props);
        const { subscribedChannels, usersDetails, localUser, provideDetails } = this.props;
        this.state = {
            filteredChannels: subscribedChannels,
            localUser: usersDetails.has(localUser.get('userAlias'))
                ? usersDetails.get(localUser.get('userAlias'))
                : localUser
        };
        this.searchChannels = this.searchChannels.bind(this);
        this.filterField = '';
        this.searchFilterActive = false;
        this.statusFilterActive = false;
        provideDetails([localUser]);
    }

    componentDidUpdate(prevProps) {
        const { subscribedChannels, usersDetails, localUser } = this.props;
        if (subscribedChannels !== prevProps.subscribedChannels) {
            this.setState({ filteredChannels: subscribedChannels });
            this.applyFilter();
        }
        if (usersDetails !== prevProps.usersDetails) {
            this.applyFilter(); // trigger here the search filter, because i'm waiting for the usersDetails map that contain the formatted name
            // format my name by ask to the global map my details
            this.setState({ localUser: usersDetails.get(localUser.get('userAlias')) });
        }
    }

    onFilterChange = (e) => {
        this.filterField = e.target.value.trimStart();
        this.applyFilter();
    };

    filterStatus = (status) => {
        this.statusFilterActive = status;
        this.applyFilter();
    };

    applyFilter() {
        let channels = this.props.subscribedChannels;
        if (this.filterField !== '') {
            channels = this.searchChannels(channels);
        }
        if (this.statusFilterActive) {
            channels = channels.filter(item => item.get('status') === 'online');
        }
        this.setState({ filteredChannels: channels });
    }

    searchChannels(channels) {
        let filteredList = [];
        const { usersDetails } = this.props;
        filteredList = channels.filter((item) => {
            let channelName = '';
            item.get('participants').forEach((p) => {
                /* no control on usersDetails, if there is subscribdChannel there are already displayed,
                    //so the Map in the state is already initialized */
                channelName += `${usersDetails.get(p.get('userAlias')).get('formattedName')} `;
            });
            channelName = channelName.toLowerCase();
            const filter = this.filterField.toLowerCase();
            return channelName.includes(filter);
        });
        return filteredList;
    }

    render() {
        const {
            selectChannel,
            headerStyle,
            bodyStyle,
            fontFamily,
            isCreatorOfChannel,
            removeChannel,
            twilio_socket_state,
            language,
            usersDetails,
            provideDetails,
            intl,
            isLoading,
            haveChat,
            chatInit
        } = this.props;
        const { filteredChannels } = this.state;
        const searchPlaceholder = intl.formatMessage({
            id: 'Channels.Search',
            defaultMessage: 'Search...'
        });
        const networkErrorLabel = intl.formatMessage({
            id: 'Error.network',
            defaultMessage: 'Network error'
        });
        const filterNoChats = intl.formatMessage({
            id: 'Channels.FilterNoChats',
            defaultMessage: 'No chats found'
        });
        const noChats = intl.formatMessage({
            id: 'Channels.NoChats',
            defaultMessage: 'No chats added yet'
        });
        const chatLoading = intl.formatMessage({
            id: 'Channels.ChatLoading',
            defaultMessage: 'Chat loading'
        });
        const chip = intl.formatMessage({
            id: 'Channels.Chip',
            defaultMessage: 'All'
        });
        const chatSDKNotInit = intl.formatMessage({
            id: 'Channels.chatSDKNotInit',
            defaultMessage: 'Chat module not initialized'
        });
        return (
            <>
                <div>
                    <Header
                        localUser={this.state.localUser}
                        headerStyle={headerStyle}
                        search={this.searchChannels}
                        call={this.props.call}
                        changeView={this.props.changeView}
                        mode={this.props.mode}
                        focusWindowCall={this.props.focusWindowCall}
                    />
                </div>
                <div className={Style['search-container']}>
                    <SearchIcon className={Style.searchIcon} />
                    <InputBase
                        className={Style['search-field']}
                        placeholder={searchPlaceholder}
                        inputProps={{ 'aria-label': 'search' }}
                        autoFocus={!window.DetectRTC.isMobileDevice}
                        onChange={this.onFilterChange}
                    />
                    {this.statusFilterActive ? (
                        <Chip
                            clickable
                            icon={<SvgIcon style={{ color: '#ffffff', padding: '1px 3px' }} component={FunnelIcon} />}
                            onClick={() => this.filterStatus(false)}
                            label="Online"
                            style={{ backgroundColor: '#42af73', color: '#ffffff' }}
                            className={Style.chipAll}
                            size="small"
                        />
                    ) : (
                        <Chip
                            variant="outlined"
                            icon={<SvgIcon className="funnelIcon" component={FunnelIcon} />}
                            clickable
                            onClick={() => this.filterStatus(true)}
                            label={chip}
                            className={Style.chipAll}
                            size="small"
                        />
                    )}
                </div>

                <div
                    className={Style['channel-container']}
                    style={{
                        background: bodyStyle.get('background'),
                        color: bodyStyle.get('color')
                    }}
                >

                    {chatInit && twilio_socket_state !== constants.TWILIO_SOCKET_CONNECTED && twilio_socket_state !== 'NOT_AUTHENTICATED' && (
                        <div className={Style.chatSockerError}>
                            <SignalWifiOffIcon fontSize="inherit" style={{ marginRight: '10px' }} />
                            {networkErrorLabel}
                        </div>
                    )}
                    <div className={Style.channels}>
                        {chatInit && twilio_socket_state === 'NOT_AUTHENTICATED' && (
                            <div className={Style.noChat}>{filterNoChats}</div>
                        )}
                        {chatInit && filteredChannels.size === 0 && (this.statusFilterActive || this.filterField) && (
                            <div className={Style.noChat}>{filterNoChats}</div>
                        )}
                        {chatInit
                            && !haveChat
                            && filteredChannels.size === 0
                            && !this.statusFilterActive
                            && !this.filterField && <div className={Style.noChat}>{noChats}</div>}
                        {chatInit
                            && haveChat
                            && filteredChannels.size === 0
                            && !this.statusFilterActive
                            && !this.filterField
                            && twilio_socket_state !== 'NOT_AUTHENTICATED'
                            && (
                            <div className={Style.noChat}>
                                    <CircularProgress className={Style.circularProgress} size={15} />
                                {chatLoading}
                            </div>
                        )}
                        {!chatInit && <div className={Style.noChat}>{chatSDKNotInit}</div>}

                        {filteredChannels.map(channel => (
                            <Channel
                                key={channel.get('uniqueName')}
                                isLoading={isLoading}
                                channel={channel}
                                selectChannel={selectChannel}
                                headerStyle={headerStyle}
                                bodyStyle={bodyStyle}
                                fontFamily={fontFamily}
                                isCreatorOfChannel={isCreatorOfChannel}
                                removeChannel={removeChannel}
                                language={language}
                                usersDetails={usersDetails}
                                provideDetails={provideDetails}
                                participants={channel.get('participants')}
                            />
                        ))}
                    </div>
                </div>
                <Divider />
            </>
        );
    }
}

Channels.propTypes = {
    selectChannel: PropTypes.func,
    removeChannel: PropTypes.func,
    deleteChannel: PropTypes.func,
    isCreatorOfChannel: PropTypes.func,
    subscribedChannels: PropTypes.instanceOf(List),
    localUser: PropTypes.instanceOf(Map),
    fontFamily: PropTypes.string,
    headerStyle: PropTypes.instanceOf(Map),
    bodyStyle: PropTypes.instanceOf(Map),
    usersDetails: PropTypes.instanceOf(Map),
    provideDetails: PropTypes.func,
    twilio_socket_state: PropTypes.string,
    language: PropTypes.string,
    intl: intlShape.isRequired,
    call: PropTypes.instanceOf(Map),
    changeView: PropTypes.func,
    focusWindowCall: PropTypes.func,
    isLoading: PropTypes.bool,
    haveChat: PropTypes.bool,
    chatInit: PropTypes.bool
};
Channels.defaultProps = {
    selectChannel: null,
    removeChannel: null,
    deleteChannel: null,
    isCreatorOfChannel: null,
    subscribedChannels: List([]),
    localUser: Map({}),
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    headerStyle: Map({}),
    bodyStyle: Map({}),
    usersDetails: Map({}),
    provideDetails: null,
    twilio_socket_state: null,
    language: 'en',
    call: Map({}),
    changeView: null,
    focusWindowCall: null,
    isLoading: false,
    haveChat: true,
    chatInit: true
};

const mapStateToProps = store => ({
    subscribedChannels: store.channels,
    localUser: store.behavior.get('localUser'),
    fontFamily: store.styles.get('fontFamily'),
    chatInit: store.behavior.get('chatInit')
});

const mapDispatchToProps = dispatch => ({ selectChannel: (uniqueName, who) => dispatch(widgetOperations.selectChannel(uniqueName, who)) });

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Channels));
