import { Map } from 'immutable';
import actionTypes from './types';
import variables from '../../../../styles/variables.scss';

const initialState = Map({
    header: Map({
        background: variables.blueBandyer,
        color: variables.bWhite,
        button: Map({
            background: `linear-gradient(0deg, ${variables.bOrange}, ${variables.bLightOrange} 150%)`,
            color: variables.bWhite
        })
    }),
    body: Map({
        background: variables.bWhite,
        color: variables.bBlack
    }),
    dial: Map({
        background: variables.blueBandyer,
        color: variables.bWhite
    }),
    launcher: Map({ background: variables.blueBandyer, color: variables.bWhite }),
    call: Map({ background: variables.blueBandyer }),
    message: Map({
        received: Map({
            background: variables.bGreyMessage,
            color: variables.bBlack
        }),
        sent: Map({
            background: variables.blueMessage,
            color: variables.bWhite
        })
    }),
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif'
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CHANGE_FONT_FAMILY:
            return state.update('fontFamily', () => action.fontFamily);
        case actionTypes.CHANGE_HEADER_STYLE_BACKGROUND:
            return state.setIn(['header', 'background'], action.style);
        case actionTypes.CHANGE_HEADER_STYLE_COLOR:
            return state.setIn(['header', 'color'], action.style);
        case actionTypes.CHANGE_HEADER_BUTTON_STYLE_BACKGROUND:
            return state.setIn(['header', 'button', 'background'], action.style);
        case actionTypes.CHANGE_HEADER_BUTTON_STYLE_COLOR:
            return state.setIn(['header', 'button', 'color'], action.style);
        case actionTypes.CHANGE_BODY_STYLE_BACKGROUND:
            return state.setIn(['body', 'background'], action.style);
        case actionTypes.CHANGE_BODY_STYLE_COLOR:
            return state.setIn(['body', 'color'], action.style);
        case actionTypes.CHANGE_LAUNCHER_STYLE_BACKGROUND:
            return state.setIn(['launcher', 'background'], action.style);
        case actionTypes.CHANGE_LAUNCHER_STYLE_COLOR:
            return state.setIn(['launcher', 'color'], action.style);
        case actionTypes.CHANGE_MESSAGE_SENT_STYLE_BACKGROUND:
            return state.setIn(['message', 'sent', 'background'], action.style);
        case actionTypes.CHANGE_MESSAGE_SENT_STYLE_COLOR:
            return state.setIn(['message', 'sent', 'color'], action.style);
        case actionTypes.CHANGE_MESSAGE_RECEIVED_STYLE_BACKGROUND:
            return state.setIn(['message', 'received', 'background'], action.style);
        case actionTypes.CHANGE_MESSAGE_RECEIVED_STYLE_COLOR:
            return state.setIn(['message', 'received', 'color'], action.style);
        case actionTypes.CHANGE_DIAL_STYLE_BACKGROUND:
            return state.setIn(['dial', 'background'], action.style);
        case actionTypes.CHANGE_DIAL_STYLE_COLOR:
            return state.setIn(['dial', 'color'], action.style);
        case actionTypes.CHANGE_CALL_STYLE_BACKGROUND:
            return state.setIn(['call', 'background'], action.style);

        default:
            return state;
    }
}
