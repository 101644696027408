import React from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidCatch(error, errorInfo) {
        // if (error.name === 'GetUserMediaError') {
        //     if (error.message === constants.SDK_GUM_ERROR) {
        //         // permission not accepted
        //         changeView(GUM_PERMISSION_NOT_ACCEPTED);
        //     } else if (error.message === constants.SDK_NO_MICROPHONE) {
        //         // no microphone
        //         changeView(GUM_NO_MICROPHONE);
        //     }
        //     // qui sono due tipi di errore, uno per no mic e uno no permissions
        // }
        Sentry.captureException(error);
    }

    render() {
        return this.props.children;
    }
}

export default connect()(ErrorBoundary);
