import { constants } from 'bandyersdkcommon';
import Util from './util';

import Channel from './channel';
import Message from './message';
import Member from './member';
import sessionStorageHandler from '../../helpers/sessionStorageHandler';
import {
    TYPING_STARTED,
    CONNECTION_STATE_CHANGED,
    TWILIO_SOCKET_DENIED,
    TWILIO_TOKEN_EXPIRED_ERROR
} from '../../constants';
import store from '../../store/store';
import { buildChannelUniqueName } from '../../helpers/utils';

const events = require('events');

class TwilioChat extends events {
    constructor(bandyerWebSdk, twilioToken) {
        super();
        this._bandyerWebSdk = bandyerWebSdk; // it is the comm center
        this._twilioChatClient = {};
        this._twilioChatClient.channels = {};
        this._sessionStorageHandler = new sessionStorageHandler();
        this._localUserAlias = twilioToken.identity;
        this._fetchedUsers = {};
        this._popup = null;
        this._clientOptions = {};
        // this._authentication = new Authentication(this);
        this._channel = new Channel(this);
        this._message = new Message(this);
        this._member = new Member(this);
        this._dateCreated = new Date();
        this._isTypingSent = false;
        this._isTypingTimeout = null;
        this._isTypingTimeoutTime = 5000;
        this._twilioTokenPromise = null;
        if (process.env.NODE_ENV !== 'development') {
            this._clientOptions = { logLevel: 'silent' };
        }
    }

    async _renewToken() {
        try {
            if (!this._twilioTokenPromise) {
                this._twilioTokenPromise = this._bandyerWebSdk.initChat(this._localUserAlias);
                const twilioToken = await this._twilioTokenPromise;
                this._twilioChatClient.updateToken(twilioToken.token);
            }
        } catch (err) {
            this._renewToken();
        } finally {
            this._twilioTokenPromise = null;
        }
    }

    async authenticate(authToken) {
        try {
            this._twilioChatClient = await Twilio.Chat.Client.create(authToken, this._clientOptions);
            if (process.env.NODE_ENV === 'development') {
                window.TC = this._twilioChatClient;
            }
            this._twilioChatClient.on('channelAdded', event => this._channel.handleChannelAdded(event));
            this._twilioChatClient.on('channelRemoved', event => this._channel.handleChannelRemoved(event));
            this._twilioChatClient.on('messageAdded', event => this._message.handleMessageAdded(event));
            this._twilioChatClient.on('memberUpdated', event => this._member.handleMemberUpdated(event.member));
            this._twilioChatClient.on('memberJoined', event => this._member.handleMemberJoined(event));
            this._twilioChatClient.on('typingStarted', event => this._member.handleTypingStarted(event));
            this._twilioChatClient.on('typingEnded', event => this._member.handleTypingEnded(event));
            this._twilioChatClient.on('tokenAboutToExpire', () => this._renewToken());
            this._twilioChatClient.on('tokenExpired', () => this._renewToken());
            this._twilioChatClient.on('connectionError', (event) => {
                if (event.errorCode === TWILIO_TOKEN_EXPIRED_ERROR) {
                    this._renewToken();
                }
            });
            this._twilioChatClient.on('connectionStateChanged', (event) => {
                if (event === TWILIO_SOCKET_DENIED) {
                    // if the twilio socket is in denied state(token expired during reconnecting)
                    this._renewToken(); // renew the token
                } else {
                    this.emit(CONNECTION_STATE_CHANGED, event);
                }
            });
            this.listenerChatEvents();
            return true;
        } catch (err) {
            return err;
        }
    }

    listenerChatEvents() {
        this._channel.on(constants.SDK_EVENTS_CHAT_LOADED, (data) => {
            this.emit(constants.SDK_EVENTS_CHAT_LOADED, data);
        });
    }

    addChannel(user = null, options = {}) {
        const checkChannel = (userAlias) => {
            const state = store.getState();
            const { channels } = state;
            let found = false;
            channels.forEach((c) => {
                const participants = c.get('participants').map(p => p.get('userAlias'));
                if (participants.includes(userAlias)) {
                    found = true;
                }
            });
            return found;
        };

        return new Promise(async(resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                if (checkChannel(user)) {
                    unsubscribe();
                    resolve();
                }
            });
            if (checkChannel(user)) {
                unsubscribe();
                resolve();
                return;
            }
            await this._channel.addChannel(user, options).catch(error => reject(error));
            if (checkChannel(user)) {
                unsubscribe();
                resolve();
            }
        });
    }

    removeChannel(user) {
        return new Promise(async(resolve, reject) => {
            const uniqueName = buildChannelUniqueName([this.localUserAlias, user]);
            const onChannelRemoved = (removedUniqueName) => {
                if (removedUniqueName === uniqueName) {
                    this._channel.off('chat_removed', onChannelRemoved);
                    resolve();
                }
            };
            const state = store.getState();
            const { channels } = state;
            let found = false;
            this._channel.on('chat_removed', onChannelRemoved);
            await this._channel.removeChannel(user).catch(error => reject(error));

            channels.forEach((c) => {
                if (c.get('uniqueName') === uniqueName) {
                    found = true;
                }
            });
            if (!found) {
                resolve();
            }
        });
    }

    // CHANNEL

    fetchSubscribedChannels() {
        return this._channel.fetchSubscribedChannels();
    }

    selectCurrentChannel(uniqueName, who) {
        return this._channel.selectCurrentChannel(uniqueName, who);
    }

    resetCurrentChannel() {
        this._twilioChatClient.currentChannel = null;
    }

    // MESSAGE

    fetchMessage(uniqueName, anchor) {
        return this._channel.fetchMessages(uniqueName, anchor);
    }

    sendMessage(uniqueName, message, options = {}) {
        return this._message.sendMessage(uniqueName, message, options);
    }

    // MEMBER

    isTyping(uniqueName) {
        if (!this._isTypingSent) {
            this._isTypingTimeout = setTimeout(() => {
                this._isTypingSent = false;
            }, this._isTypingTimeoutTime);
            this._isTypingSent = true;
            this.emit(
                TYPING_STARTED,
                Util.buildIsTypingObj(this._twilioChatClient.channels[uniqueName], this._localUserAlias)
            );
        }
        return this._member.isTyping();
    }

    // LOGOUT HANDLER

    logout() {
        this._sessionStorageHandler.clear();
        return this._twilioChatClient.shutdown();
    }

    // ------------ UTILITY FOR DEBUGGING --------------------- //

    getUser(userAlias) {
        this._twilioChatClient.getUser(userAlias);
    }

    get localUserAlias() {
        return this._localUserAlias;
    }

    get bandyerWebSdk() {
        return this._bandyerWebSdk;
    }

    get connectionState() {
        return this._twilioChatClient.connectionState;
    }
}

export default TwilioChat;
