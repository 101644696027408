import { constants, BandyerEnum } from 'bandyersdkcommon';
import * as CommunicationCenter from '@bandyer/web-communication-center';
import chat from './chat';
import call from './call';
import store from '../store/store';
import { MESSAGE_SENT, MESSAGE_RECEIVED, TYPING_STARTED, MESSAGE_READ, CONNECTION_STATE_CHANGED } from '../constants';
import { WIDGET_INSTANCE_ALREADY_INITIATED } from '../helpers/errorHandler';
import {
    setUserStatusInChannel,
    resetCall,
    updateCommunicationCenterState,
    updateTwilioSocketState,
    setHaveChat,
    publishWebcam
} from '../store/actions/dispatcher';
import { buildChannelUniqueName } from '../helpers/utils';
import UserDetailsService from './userDetails';

const events = require('events');

const packageName = require('../../package.json').name;
const packageVersion = require('../../package.json').version;

class ServiceClass extends events {
    constructor(communicationCenter, userAlias, screenSharingExtensionId, appId, environment) {
        super();
        this._commCenter = communicationCenter;
        this._screenSharingExtensionId = screenSharingExtensionId;
        this._localUserAlias = userAlias;
        this._services = {};
        this._userAlias = userAlias;
        this._appId = appId;
        this._initializationPromise = null;
        // create environment pointer for rest API
        this._environment = (() => {
            switch (environment) {
                case 'development':
                    return 'develop';
                default:
                    return environment;
            }
        })();
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'demo') {
            window.communicationCenter = this._commCenter;
            this._commCenter.setLogLevel(1);
        }
    }

    static init({ userAlias, appId, environment, screenSharingExtensionId }) {
        const commCenterInstance = CommunicationCenter.initialize(userAlias, appId, environment, {
            clientName: packageName,
            clientVersion: packageVersion
        });
        return new ServiceClass(commCenterInstance, userAlias, screenSharingExtensionId, appId, environment);
    }

    async initCommunicationCenter() {
        this._registerUserConnectedEvent();
        await this._commCenter.connect();
        this._services.call = call.initialize(this._commCenter, this._screenSharingExtensionId);
        this._registerCommunicationCenterEvents();
        this._services.call.registerCommunicationCenterEvents();
        this._registerSocketEvent();
        updateCommunicationCenterState(constants.COMMUNICATION_CENTER_CONNECTED); // update state
        return this._commCenter;
        /* this._registerUserConnectedEvent();
        this._registerCommunicationCenterEvents(); */
    }

    async initChatService() {
        if (this._initializationPromise) {
            return this._initializationPromise;
        }
        this._initializationPromise = new Promise(async(resolve, reject) => {
            const twilioToken = await this._commCenter.initChat(this._localUserAlias);
            this._services.chat = new chat(this._commCenter, twilioToken);
            this._services.chat.on(TYPING_STARTED, data => this.emit(TYPING_STARTED, data));
            this._services.chat.on(MESSAGE_SENT, data => this.emit(MESSAGE_SENT, data));
            this._services.chat.on(MESSAGE_RECEIVED, data => this.emit(MESSAGE_RECEIVED, data));
            this._services.chat.on(MESSAGE_READ, data => this.emit(MESSAGE_READ, data));
            this._services.chat.on(constants.SDK_EVENTS_CHAT_LOADED, (data) => {
                this.emit(constants.SDK_EVENTS_CHAT_LOADED, data);
            });

            this._services.chat.on(CONNECTION_STATE_CHANGED, async(event) => {
                switch (event) {
                    case constants.TWILIO_SOCKET_CONNECTED: {
                        const remoteChannels = await this._services.chat._twilioChatClient.getSubscribedChannels();
                        setHaveChat(remoteChannels.items.length > 0);
                        updateTwilioSocketState(constants.TWILIO_SOCKET_CONNECTED);
                        resolve();
                        break;
                    }
                    case constants.TWILIO_SOCKET_CONNECTING:
                        /* when there is an updateToken event the twilio socket go in reconnecting and then in connected, this event
                         *flash the loader, so  the setTimeout handle this case
                         */
                        setTimeout(() => {
                            if (this._services.chat.connectionState === constants.TWILIO_SOCKET_CONNECTING) {
                                updateTwilioSocketState(constants.TWILIO_SOCKET_CONNECTING);
                            }
                        }, 1000);
                        break;
                    case constants.TWILIO_SOCKET_DISCONNECTED:
                        updateTwilioSocketState(constants.TWILIO_SOCKET_DISCONNECTED);
                        reject();
                        break;
                    default:
                }
            });
            await this._services.chat.authenticate(twilioToken.token);
        });


        return this._initializationPromise;
    }

    async getUser(userAlias) {
        return this._commCenter.getUser(userAlias);
    }

    async getUserStatus(userAlias) {
        try {
            const user = await this._commCenter.getUserStatus(userAlias);
            return {
                userAlias: user.user.userAlias,
                status: user.status
            };
        } catch (e) {
            throw e;
        }
    }

    async getUsersStatusList() {
        try {
            const users = await this._commCenter.getUsersStatusList();
            const toReturn = [];
            users.forEach((user) => {
                toReturn.push({
                    userAlias: user.userAlias,
                    status: user.status
                });
            });
            return toReturn;
        } catch (e) {
            throw e;
        }
    }

    async logout() {
        await this._services.chat.logout();
        return this._commCenter.shutdown();
    }

    handleUserStatusInChannel(user, status) {
        const buildUniqueName = buildChannelUniqueName([this._localUserAlias, user.userAlias]);
        setUserStatusInChannel(buildUniqueName, status);
    }

    _registerUserConnectedEvent() {
        this._commCenter.on(constants.SDK_EVENTS_USER_CONNECTED, (data) => {
            if (data && this._localUserAlias !== data.userAlias) {
                this.handleUserStatusInChannel(data, BandyerEnum.UserStatus.ONLINE);
            }
            const toEmit = {
                userAlias: data.userAlias,
                status: BandyerEnum.UserStatus.ONLINE
            };
            this.emit(constants.SDK_EVENTS_USER_CONNECTED, toEmit);
        });
        this._commCenter.on(constants.SDK_EVENTS_USER_DISCONNECTED, async(data) => {
            if (data && this._localUserAlias !== data.userAlias) {
                // check if there is another instance online of the user
                const userinfo = await this._commCenter.getUserStatus(data.userAlias);
                if (userinfo.status === BandyerEnum.UserStatus.ONLINE) {
                    this.handleUserStatusInChannel(data, BandyerEnum.UserStatus.ONLINE);
                } else {
                    this.handleUserStatusInChannel(data, BandyerEnum.UserStatus.OFFLINE);
                    const toEmit = {
                        userAlias: data.userAlias,
                        status: BandyerEnum.UserStatus.OFFLINE
                    };
                    this.emit(constants.SDK_EVENTS_USER_DISCONNECTED, toEmit);
                }
            }
        });
    }

    _registerSocketEvent() {
        // handle the communication center socket events
        this._commCenter.on(constants.SDK_SOCKET_CONNECTION_DISCONNECTED, () => {
            const { behavior } = store.getState();
            const call = behavior.get('call');
            updateCommunicationCenterState(constants.COMMUNICATION_CENTER_DISCONNECTED); // update state
            // check if there is an active call and his status
            if (call) {
                if (
                    call.get('callStatus') === BandyerEnum.CallStatus.DIALING
                    || call.get('callStatus') === BandyerEnum.CallStatus.RINGING
                ) {
                    resetCall();
                }
            }
        });
        this._commCenter.on(constants.SDK_SOCKET_CONNECTION_RECONNECTING, () => {
            const { behavior } = store.getState();
            const call = behavior.get('call');
            updateCommunicationCenterState(constants.COMMUNICATION_CENTER_CONNECTING); // update state
            if (call) {
                if (
                    call.get('callStatus') === BandyerEnum.CallStatus.DIALING
                    || call.get('callStatus') === BandyerEnum.CallStatus.RINGING
                ) {
                    resetCall();
                }
            }
        });
        this._commCenter.on(constants.SDK_SOCKET_CONNECTION_RECONNECT, () => {
            const { behavior } = store.getState();
            const call = behavior.get('call');
            updateCommunicationCenterState(constants.COMMUNICATION_CENTER_CONNECTED); // update state
            if (call) {
                if (!(call.get('callStatus') === BandyerEnum.CallStatus.CONNECTED)) {
                    resetCall();
                }
            } else {
                resetCall();
            }
        });
    }

    _registerCommunicationCenterEvents() {
        this._commCenter.on(constants.SDK_EVENTS_CALL_INCOMING, (data) => {
            const callParticipants = [];
            data.callParticipants.forEach((p) => {
                callParticipants.push(p.user.userAlias);
            });
            const toEmit = {
                callAlias: data.callAlias,
                callParticipants,
                callDirection: data.callDirection === 'outgoing' ? 'outgoing' : 'incoming',
                callOptions: data._callOptions
            };
            this.emit(constants.SDK_EVENTS_CALL_INCOMING, toEmit);
        });
        this.services.call.on(constants.SDK_EVENTS_CALL_DIAL_ANSWERED, data => this.emit(constants.SDK_EVENTS_CALL_DIAL_ANSWERED, data));
        this.services.call.on(constants.SDK_EVENTS_CALL_DIAL_DECLINED, data => this.emit(constants.SDK_EVENTS_CALL_DIAL_DECLINED, data));
        this.services.call.on(constants.SDK_EVENTS_CALL_DIAL_STOPPED, data => this.emit(constants.SDK_EVENTS_CALL_DIAL_STOPPED, data));
        this.services.call.on(constants.SDK_EVENTS_CALL_DELETED, data => this.emit(constants.SDK_EVENTS_CALL_DELETED, data));
        this.services.call.on('call_started', data => this.emit('call_started', data));
        this.services.call.on('call_ended', data => this.emit('call_ended', data));
    }

    initUserDetailsService(userdetailsProvider, userDetailsFormatter) {
        this._services.userDetailsService = new UserDetailsService(userdetailsProvider, userDetailsFormatter);
    }

    get services() {
        return this._services;
    }

    get communicationCenter() {
        return this._commCenter;
    }

    get userAlias() {
        return this._userAlias;
    }

    get screenSharingExtensionId() {
        return this._screenSharingExtensionId;
    }

    get callType() {
        return this._callType;
    }

    get environment() {
        return this._environment;
    }

    get appId() {
        return this._appId;
    }
}

/**
 * This function initialize the service instance once. The service is initialize in src/components/widget/redux/operations.
 *
 */
const Service = function() {
    let instance = null;

    function createServiceInstance({ userAlias, appId, environment, screenSharingExtensionId }) {
        const service = ServiceClass.init({
            userAlias,
            appId,
            environment,
            screenSharingExtensionId
        });
        return service;
    }

    return {
        getInstance: () => instance,
        initialize: ({ userAlias, appId, environment, screenSharingExtensionId }) => {
            if (instance) {
                throw new Error(WIDGET_INSTANCE_ALREADY_INITIATED);
            }
            instance = createServiceInstance({
                userAlias,
                appId,
                environment,
                screenSharingExtensionId
            });
        },
        shutdown: () => {
            CommunicationCenter.shutdown();
            instance = null;
        }
    };
};

export default Service();
