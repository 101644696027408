import React from 'react';
import PropTypes from 'prop-types';
import { constants } from 'bandyersdkcommon';
import { Tooltip, Fab, IconButton, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import SendIcon from '@material-ui/icons/Send';
import Style from './style.scss';

const ToolTipErrorNetwork = <FormattedMessage id="Error.network" defaultMessage="Network error" />;

const Sender = ({ socketState, sendMessage, placeholder, disabledInput, isTyping }) => (
    <form className={Style.sender} onSubmit={sendMessage}>
        <input
            type="text"
            className={Style['new-message']}
            id="new-message"
            name="message"
            placeholder={placeholder}
            disabled={disabledInput}
            autoFocus={!window.DetectRTC.isMobileDevice}
            autoComplete="off"
            onKeyPress={event => isTyping(event)}
        />
        <Tooltip placement="left" title={socketState !== constants.TWILIO_SOCKET_CONNECTED ? ToolTipErrorNetwork : ''}>
            <div>
                <Button
                    size="small"
                    type="submit"
                    className={Style.send}
                    disabled={socketState !== constants.TWILIO_SOCKET_CONNECTED}
                >
                    <SendIcon className={Style['send-icon']} />
                </Button>
            </div>
        </Tooltip>
    </form>
);

Sender.propTypes = {
    socketState: PropTypes.string,
    sendMessage: PropTypes.func,
    placeholder: PropTypes.string,
    disabledInput: PropTypes.bool,
    isTyping: PropTypes.func
};

Sender.defaultProps = {
    socketState: null,
    sendMessage: null,
    placeholder: null,
    disabledInput: false,
    isTyping: null
};

export default Sender;
