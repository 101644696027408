import React, { PureComponent } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import Button from '@material-ui/core/Button';
import Chat from '@material-ui/icons/Chat';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Fab } from '@material-ui/core';
import {
    GUM_ERROR,
    GENERIC_CALL_ERROR,
    USERS_BUSY_ERROR,
    INVALID_PERMISSION_CAN_VIDEO,
    INITIATOR_NOT_AVAILABLE,
    SOCKET_CALL_ERROR,
    MULTIPLE_SOCKET_ERROR,
    JOIN_CALL_INVALID_URL,
    JOIN_CALL_REQUEST_FAILED,
    JOIN_CALL_INVALID_MTM,
    ACTION_ALREADY_TAKEN
} from '../../../constants';
import Style from '../style.scss';

class GenericCallError extends PureComponent {
    constructor() {
        super();
        this.state = { action: null };
    }

    componentDidMount() {
        const { errorKey } = this.props;
        this.calculateErrorKey(errorKey);
    }

    componentDidUpdate(prevProps) {
        const { errorKey } = this.props;
        if (errorKey !== prevProps.errorKey) {
            this.calculateErrorKey(errorKey);
        }
    }

    calculateErrorKey = errorKey => {
        switch (errorKey) {
            case ACTION_ALREADY_TAKEN:
                return (
                    <>
                        <p>
                            <FormattedMessage
                                id="Error.actionAlreadyTakenPrincipal"
                                defaultMessage="The call was answered on another device"
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="Error.actionAlreadyTakenSecondary"
                                defaultMessage="Please check the other devices"
                            />
                        </p>
                    </>
                );
            case JOIN_CALL_INVALID_MTM:
                return (
                    <>
                        <p>
                            <FormattedMessage
                                id="Error.joinCallInvalidMTMPrincipal"
                                defaultMessage="Many to many not supported yet"
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="Error.joinCallInvalidMTMSecondary"
                                defaultMessage="Please open your URL in another tab"
                            />
                        </p>
                    </>
                );
            case JOIN_CALL_INVALID_URL:
                return (
                    <>
                        <p>
                            <FormattedMessage
                                id="Error.joinCallInvalidURLPrincipal"
                                defaultMessage="Join call invalid url"
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="Error.joinCallInvalidURLSecondary"
                                defaultMessage="Please check your URL"
                            />
                        </p>
                    </>
                );
            case JOIN_CALL_REQUEST_FAILED:
                return (
                    <>
                        <p>
                            <FormattedMessage
                                id="Error.joinCallRequestFailedPrimary"
                                defaultMessage="Join Call request Failed"
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="Error.joinCallRequestFailedSecondary"
                                defaultMessage="Please retry later"
                            />
                        </p>
                    </>
                );
            case SOCKET_CALL_ERROR:
                return (
                    <>
                        <p>
                            <FormattedMessage id="Error.socketCallErrorPrincipal" defaultMessage="Socket call error" />
                        </p>
                        <p>
                            <FormattedMessage id="Error.socketCallErrorSecondary" defaultMessage="Please retry later" />
                        </p>
                    </>
                );
            case GUM_ERROR:
                return (
                    <>
                        <p>
                            <FormattedMessage id="Error.gumErrorPrincipal" defaultMessage="Check your permission" />
                        </p>
                        <p>
                            <FormattedMessage
                                id="Error.gumErrorSecondary"
                                defaultMessage="There was a problem on getting audio/video permission"
                            />
                        </p>
                    </>
                );
            case GENERIC_CALL_ERROR:
                return (
                    <>
                        <p>
                            <FormattedMessage id="Error.genericCallErrorPrincipal" defaultMessage="hola pr" />
                        </p>
                        <p>
                            <FormattedMessage id="Error.genericCallErrorSecondary" defaultMessage="hola sc" />
                        </p>
                    </>
                );
            case INVALID_PERMISSION_CAN_VIDEO:
                return (
                    <>
                        <p>
                            <FormattedMessage id="Error.notValidPermissionCanVideoPrincipal" />
                        </p>
                        <p>
                            <FormattedMessage id="Error.notValidPermissionCanVideoSecondary" />
                        </p>
                    </>
                );
            case INITIATOR_NOT_AVAILABLE:
                return (
                    <>
                        <p>
                            <FormattedMessage id="Error.initiatorNotAvailablePrincipal" />
                        </p>
                        <p>
                            <FormattedMessage id="Error.initiatorNotAvailableSecondary" />
                        </p>
                    </>
                );
            case USERS_BUSY_ERROR:
                return (
                    <>
                        <p>
                            <FormattedMessage id="Error.usersBusyPrincipal" />
                        </p>
                        <p>
                            <FormattedMessage id="Error.usersBusySecondary" />
                        </p>
                    </>
                );
            case MULTIPLE_SOCKET_ERROR:
                return (
                    <>
                        <p>
                            <FormattedMessage id="Error.multipleSocketErrorPrincipal" />
                        </p>
                        <p>
                            <FormattedMessage id="Error.multipleSocketErrorSecondary" />
                        </p>
                    </>
                );
            default:
                return (
                    <>
                        <p>
                            <FormattedMessage id="Error.genericCallErrorPrincipal" defaultMessage="hola pr" />
                        </p>
                        <p>
                            <FormattedMessage id="Error.genericCallErrorSecondary" defaultMessage="hola sc" />
                        </p>
                    </>
                );
        }
        return null;
    };

    render() {
        const { bodyStyle, headerStyle, errorKey, changeToChannel } = this.props;
        return (
            <div
                className={Style['error-container']}
                style={{
                    background: bodyStyle.get('background'),
                    color: bodyStyle.get('color')
                }}
            >
                <div className={Style['main-error']}>
                    {this.calculateErrorKey(errorKey)}
                    <Fab
                        style={{
                            background: headerStyle.get('button').get('background'),
                            color: headerStyle.get('button').get('color')
                        }}
                        aria-label="back to chat"
                        onClick={changeToChannel}
                        className={Style['main-controls']}
                    >
                        <Chat />
                    </Fab>

                    <p>
                        <FormattedMessage id="Error.BackToChat" defaultMessage="hola sc" />
                    </p>
                </div>
            </div>
        );
    }
}

GenericCallError.propTypes = {
    changeToChannel: PropTypes.func,
    headerStyle: PropTypes.instanceOf(Map),
    bodyStyle: PropTypes.instanceOf(Map),
    errorKey: PropTypes.string
};

GenericCallError.defaultProps = {
    changeToChannel: null,
    headerStyle: Map({}),
    bodyStyle: Map({}),
    errorKey: ''
};

export default GenericCallError;
