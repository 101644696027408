import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Loader from 'react-loaders';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Call from '@material-ui/icons/Call';
import CallEnd from '@material-ui/icons/CallEnd';
import { FormattedMessage } from 'react-intl';
import { Fab } from '@material-ui/core';
import { fetchUserImage } from '../../../../../../helpers/utils';
import Style from './style.scss';

class DialIn extends PureComponent {
    constructor(props) {
        super(props);
        const { usersDetails } = this.props;
        const callInitiator = this.parseUser();
        this.state = {
            actionPerformed: false,
            callInitiator: usersDetails.has(callInitiator.get('userAlias'))
                ? usersDetails.get(callInitiator.get('userAlias'))
                : callInitiator
        };
    }

    componentDidMount() {
        const { provideDetails } = this.props;
        provideDetails([this.parseUser()]);
    }

    componentDidUpdate(prevProps) {
        const { usersDetails } = this.props;
        const callInitiator = this.parseUser();
        if (usersDetails !== prevProps.usersDetails) {
            const user = usersDetails.get(callInitiator.get('userAlias'));
            this.setState({ callInitiator: user });
        }
    }

    parseUser = () => {
        const { call } = this.props;
        const toReturn = call.get('callInitiator');
        if (toReturn && toReturn.has('user')) {
            return toReturn.get('user');
        }
        return {};
    };

    decline = () => {
        const { decline, call } = this.props;
        const { actionPerformed } = this.state;
        const audioCall = document.getElementById('audioCall');
        if (!actionPerformed) {
            audioCall.pause();
            audioCall.removeAttribute('src'); // empty source
            audioCall.load();
            this.setState({ actionPerformed: true });
            decline(call);
        }
    };

    answer = () => {
        const { answer, call } = this.props;
        const { actionPerformed } = this.state;
        const audioCall = document.getElementById('audioCall');
        if (!actionPerformed) {
            audioCall.pause();
            audioCall.removeAttribute('src'); // empty source
            audioCall.load();
            this.setState({ actionPerformed: true });
            answer(call);
        }
    };

    getCallInitiatorName = () => {
        const { callInitiator } = this.state;
        return callInitiator.has('formattedName') ? callInitiator.get('formattedName') : callInitiator.get('userAlias');
    };

    render() {
        const { dialStyle } = this.props;
        const { callInitiator } = this.state;
        return (
            <div
                className={Style['dial-in-container']}
                style={{
                    color: dialStyle.get('color'),
                    background: dialStyle.get('background')
                }}
            >
                <Grid
                    style={{ margin: '0px' }}
                    spacing={3}
                    className={Style.actions}
                    container
                    alignItems="center"
                    direction="column"
                >
                    <Grid item>
                        <Avatar src={fetchUserImage(callInitiator)} className={Style['big-avatar']} />
                    </Grid>
                    <Grid item>
                        <div style={{ color: dialStyle.get('color') }}>
                            {this.getCallInitiatorName()}{' '}
                            <FormattedMessage id="Channels.Callin" defaultMessage="is calling you" />
                        </div>
                    </Grid>
                    <Grid item>
                        <Grid spacing={5} container justify="space-between" alignItems="center" direction="row">
                            <Grid item>
                                <Fab
                                    aria-label="call-reject"
                                    className={Style['reject-call']}
                                    onClick={() => this.decline()}
                                >
                                    <CallEnd />
                                </Fab>
                            </Grid>
                            <Grid item>
                                <Fab
                                    aria-label="call-response"
                                    className={Style['accept-call']}
                                    onClick={() => this.answer()}
                                >
                                    <Call />
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <audio
                    id="audioCall"
                    src="https://cdn.bandyer.com/sdk/js/resources/media/ringingIn.mp3"
                    autoPlay
                    loop
                />
            </div>
        );
    }
}

DialIn.propTypes = {
    answer: PropTypes.func,
    decline: PropTypes.func,
    call: PropTypes.instanceOf(Map),
    dialStyle: PropTypes.instanceOf(Map),
    usersDetails: PropTypes.instanceOf(Map),
    provideDetails: PropTypes.func
};

DialIn.defaultProps = {
    answer: null,
    decline: null,
    call: Map({}),
    dialStyle: Map({}),
    usersDetails: Map({}),
    provideDetails: null
};

export default DialIn;
