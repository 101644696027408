import React, { PureComponent } from 'react';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Style from '../../../channels/style.scss';
import { Grid } from '@material-ui/core';
import { buildLastMessage, buildLastMessageTimestamp } from '../../../../../../../../helpers/utils';
import UserStatus from '../UserStatus';

class Channel extends PureComponent {
    constructor(props) {
        super(props);
        const { participants, usersDetails } = this.props;
        this.state = {
            open: false,
            channelName: 'GUEST GROUP',
            // get participant from the channel or from the store if exists(cache)
            participant: null
        };
    }

    componentDidMount() {
        const { participants, provideDetails, usersDetails } = this.props;
        provideDetails(participants);
        if (participants.size > 1) {
            // handle group name
            let formattedName = '';
            // groupName is a join of formatted name users's
            participants.forEach(u => {
                const userAlias = u.get('userAlias');
                if (usersDetails && usersDetails.has(userAlias)) {
                    formattedName = `${formattedName + usersDetails.get(userAlias).get('formattedName')} `;
                } else {
                    formattedName = `${formattedName + userAlias} `;
                }
            });
            this.setState({ groupName: formattedName });
        } else {
            const participantAlias = participants.get(0).get('userAlias'); // if there is a one to one, only one participant
            // set the entire single participant, so that pass them to the userStatus for getting the image //FIX for group image(not defined yet)
            this.setState({ participant: usersDetails.get(participantAlias) });
        }
    }

    componentDidUpdate(prevProps) {
        // directly get participants from props, because the participants from the channel result not already fullfilled and return get of undefined
        const { participants, usersDetails } = this.props;
        if (usersDetails !== prevProps.usersDetails) {
            if (participants.size > 1) {
                // handle group name
                let formattedName = '';
                // groupName is a join of formatted name users's
                participants.forEach(u => {
                    const userAlias = u.get('userAlias');
                    formattedName = `${formattedName + usersDetails.get(userAlias).get('formattedName')} `;
                });
                this.setState({ groupName: formattedName });
            } else {
                const participantAlias = participants.get(0).get('userAlias'); // if there is a one to one, only one participant
                // set the entire single participant, so that pass them to the userStatus for getting the image //FIX for group image(not defined yet)
                this.setState({ participant: usersDetails.get(participantAlias) });
            }
        }
    }

    onSelectChannel = () => {
        const { channel, selectChannel, isLoading } = this.props;
        if (!isLoading) {
            selectChannel(channel.get('uniqueName'), channel.get('participants'));
        }
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    deleteChannel = () => {
        const { channel, removeChannel } = this.props;
        removeChannel(channel.get('participants'));
        this.handleClose();
    };

    getChannelname = () => {
        const { participants } = this.props;
        const { participant, groupName } = this.state;
        if (!participant) {
            return '';
        }
        return participants > 1 ? groupName : participant.get('formattedName');
    };

    render() {
        const { channel, fontFamily, bodyStyle, headerStyle, isCreatorOfChannel, language } = this.props;
        const { open, participant } = this.state;
        return (
            <div className={Style['channel-item']} key={channel.get('uniqueName')}>
                <div className={Style['channel-body']} onClick={this.onSelectChannel}>
                    <UserStatus status={channel.get('status')} participants={participant} />
                    <div className={Style['channel-message']}>
                        <Grid container>
                            <Grid container style={{ paddingBottom: '5px', paddingTop: '5px' }}>
                                <Grid item xs={8}>
                                    <div
                                        style={{
                                            fontFamily,
                                            color: bodyStyle.get('color')
                                        }}
                                        className={Style.username}
                                    >
                                        {this.getChannelname()}
                                    </div>
                                </Grid>
                                <Grid className={Style.info} item xs={4}>
                                    <div className={Style.timestamp}>
                                        <span>{buildLastMessageTimestamp(channel.get('lastMessage'), language)}</span>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={8}>
                                    <div
                                        className={Style['last-message']}
                                        style={{ fontFamily, color: bodyStyle.get('color') }}
                                    >
                                        {channel.get('isTyping') ? (
                                            <FormattedMessage id="Channels.Typing" defaultMessage="Is typing..." />
                                        ) : (
                                            <span>{buildLastMessage(channel.get('lastMessage'))}</span>
                                        )}
                                    </div>
                                </Grid>
                                <Grid className={Style.info} item xs={4}>
                                    <div className={Style['unread-message']}>
                                        {Boolean(channel.get('unreadMessage')) && (
                                            <span
                                                style={{
                                                    fontFamily,
                                                    color: headerStyle.get('color'),
                                                    background: headerStyle.get('background')
                                                }}
                                            >
                                                {channel.get('unreadMessage')}
                                            </span>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

Channel.propTypes = {
    channel: PropTypes.instanceOf(Map),
    participants: PropTypes.instanceOf(List),
    selectChannel: PropTypes.func,
    removeChannel: PropTypes.func,
    isCreatorOfChannel: PropTypes.func,
    fontFamily: PropTypes.string,
    headerStyle: PropTypes.instanceOf(Map),
    bodyStyle: PropTypes.instanceOf(Map),
    language: PropTypes.string,
    usersDetails: PropTypes.instanceOf(Map),
    provideDetails: PropTypes.func,
    isLoading: PropTypes.bool
};
Channel.defaultProps = {
    channel: null,
    participants: null,
    selectChannel: null,
    removeChannel: null,
    isCreatorOfChannel: null,
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    headerStyle: Map({}),
    bodyStyle: Map({}),
    language: 'en',
    usersDetails: Map({}),
    provideDetails: null,
    isLoading: false
};

export default Channel;
