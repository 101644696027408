import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Map } from 'immutable';
import { Fab, Tooltip, SvgIcon } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as FileShareIcon } from '../../../../../../../../assets/icon/File_Share.svg';
import Style from '../../style.scss';

class FileShareButton extends PureComponent {
    getNotification = () => {
        const { downloadNotification } = this.props;
        const callTopContainer = document.getElementById(Style['call-top-container']);
        if (callTopContainer && downloadNotification === 1) {
            callTopContainer.className = Style['call-top-container'].concat(' ', Style['show-hide']);
        }
        return downloadNotification;
    };

    render() {
        const { headerStyle, toggleFileShare, downloadNotification } = this.props;
        const toolTipgear = <FormattedMessage id="Call.ToolTipFileShare" defaultMessage="File share" />;
        return (
            <Tooltip title={toolTipgear}>
                <Fab
                    size="small"
                    style={{
                        background: headerStyle.get('button').get('background'),
                        color: headerStyle.get('button').get('color')
                    }}
                    className={Style.callTopButtons}
                    onClick={toggleFileShare}
                >
                    <SvgIcon component={FileShareIcon} style={{ color: headerStyle.get('button').get('color') }} />
                    {!!this.getNotification() && (
                        <span className={Style['notification-badge']}>{downloadNotification}</span>
                    )}
                    {/* <AttachFileIcon style={{ color: headerStyle.get('button').get('color') }} /> */}
                </Fab>
            </Tooltip>
        );
    }
}

FileShareButton.propTypes = {
    headerStyle: PropTypes.instanceOf(Map),
    toggleFileShare: PropTypes.func,
    downloadNotification: PropTypes.number
};

FileShareButton.defaultProps = {
    headerStyle: Map({}),
    toggleFileShare: null,
    downloadNotification: 0
};

export default FileShareButton;
