import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import Loader from 'react-loaders';
import CallEnd from '@material-ui/icons/CallEnd';
import { Fab, Grid } from '@material-ui/core';
import Style from './style.scss';

class DialOut extends PureComponent {
    componentDidMount() {
        const { provideDetails, call } = this.props;
        const participants = [];
        for (const p of call.get('callParticipants')) {
            const userAlias = p.get('user');
            participants.push(userAlias);
        }
        provideDetails(participants);
    }

    buildParticipantList = () => {
        const { call, usersDetails } = this.props;
        const callParticipants = call.get('callParticipants');
        const callInitiator = call.get('callInitiator');
        const toReturn = [];
        for (const p of callParticipants) {
            const userAlias = p.get('user').get('userAlias');
            if (userAlias !== callInitiator.get('user').get('userAlias')) {
                toReturn.push(
                    usersDetails && usersDetails.has(userAlias)
                        ? usersDetails.get(userAlias).get('formattedName')
                        : userAlias
                );
            }
        }
        return toReturn.join(',');
    };

    render() {
        const { call, dialStyle, hangUp } = this.props;
        return (
            <div
                className={Style['dial-out-container']}
                style={{
                    color: dialStyle.get('color'),
                    background: dialStyle.get('background')
                }}
            >
                <Grid
                    style={{ margin: '0px' }}
                    spacing={3}
                    className={Style.actions}
                    container
                    alignItems="center"
                    justify="space-between"
                    direction="column"
                >
                    <Grid item>
                        <div style={{ color: dialStyle.get('color') }}>
                            <FormattedMessage id="Channels.Callout" defaultMessage="You are calling" />
                            {` ${this.buildParticipantList()}`}
                        </div>
                    </Grid>
                    <Grid item>
                        <Fab aria-label="hangup-reject" className={Style['hangup-call']} onClick={() => hangUp(call)}>
                            <CallEnd />
                        </Fab>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

DialOut.propTypes = {
    hangUp: PropTypes.func,
    call: PropTypes.instanceOf(Map),
    dialStyle: PropTypes.instanceOf(Map),
    usersDetails: PropTypes.instanceOf(Map),
    provideDetails: PropTypes.func
};

DialOut.defaultProps = {
    hangUp: null,
    call: Map({}),
    dialStyle: Map({}),
    usersDetails: Map({}),
    provideDetails: null
};

export default DialOut;
