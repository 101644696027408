const events = require('events');
import Logger from '../../logger/index';
import Util from './util';
import { channelIsTyping } from '../../store/actions/dispatcher';
import { MESSAGE_READ } from '../../constants';

class Member extends events {
    constructor(client) {
        super();
        this._client = client;
        this._Util = new Util(this._client);
    }

    // MEMBERS EVENTS HANDLER

    async handleMemberUpdated(member) {
        Logger.debug('_handleMemberUpdated', member);
        const messageRead = await Util.getMessageFromCurrentChannel(
            this._client._twilioChatClient.channels[member.channel.state.uniqueName],
            member.state.lastConsumedMessageIndex,
            this._client.localUserAlias
        );
        this._client.emit(MESSAGE_READ, messageRead);
    }

    handleMemberJoined(member) {
        Logger.debug('_handleMemberJoined', member);
    }

    // TYPING EVENTS HANDLER

    handleTypingStarted(member) {
        if (this._client._twilioChatClient.channels[member.channel.state.uniqueName]) {
            channelIsTyping(member.channel.state.uniqueName, true);
        }
    }

    handleTypingEnded(member) {
        if (this._client._twilioChatClient.channels[member.channel.state.uniqueName]) {
            channelIsTyping(member.channel.state.uniqueName, false);
            this._client.emit('typingEnded', Util.buildIsTypingObj(member));
        }
    }

    // MEMBERS HANDLER

    isTyping() {
        return this._client._twilioChatClient.channels[
            this._client._twilioChatClient.currentChannel.uniqueName
        ].typing();
    }
}

export default Member;
