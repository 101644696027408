import { fromJS, Map } from 'immutable';
import { MESSAGES_TYPES, MESSAGE_SENDER } from 'constants';

import Message from 'messagesComponents/Message';
// import Snippet from 'messagesComponents/Snippet';
import Activity from 'messagesComponents/Activity';

export function createNewMessage({ text, sender, index, readTimestamp, status, timestamp }) {
    return Map({
        type: MESSAGES_TYPES.TEXT,
        component: Message,
        text,
        index,
        sender,
        showAvatar: sender === MESSAGE_SENDER.RESPONSE,
        readTimestamp,
        status,
        timestamp
    });
}

export function createNewActivity({ text, sender, index, readTimestamp, status, timestamp }) {
    return Map({
        type: MESSAGES_TYPES.ACTIVITY,
        component: Activity,
        text,
        index,
        sender,
        showAvatar: sender === MESSAGE_SENDER.RESPONSE,
        readTimestamp,
        status,
        timestamp
    });
}

// export function createLinkSnippet(link) {
//     return Map({
//         type: MESSAGES_TYPES.SNIPPET.LINK,
//         component: Snippet,
//         title: link.title,
//         link: link.link,
//         sender: MESSAGE_SENDER.RESPONSE,
//         showAvatar: true
//     });
// }

export function createComponentMessage(component, props, showAvatar = false) {
    return Map({
        type: MESSAGES_TYPES.CUSTOM_COMPONENT,
        component,
        props,
        sender: MESSAGE_SENDER.RESPONSE,
        showAvatar
    });
}

// export function addNewChannel({ type, uniqueName, participants, lastMessage, unreadMessage }) {
//     return Map({
//         type,
//         uniqueName,
//         participants,
//         lastMessage,
//         unreadMessage
//     });
// }

export function loadMessages(messages) {
    const list = [];
    // qui devo creare gli elementi giusti
    for (const m of messages) {
        switch (m.component) {
            case MESSAGES_TYPES.ACTIVITY:
                list.push(
                    createNewActivity({
                        text: m.message,
                        sender: m.sender,
                        index: m.index,
                        readTimestamp: m.readTimestamp,
                        timestamp: m.timestamp,
                        status: m.status
                    })
                );
                break;
            case MESSAGES_TYPES.TEXT:
            default:
                list.push(
                    createNewMessage({
                        text: m.message,
                        sender: m.sender,
                        index: m.index,
                        readTimestamp: m.readTimestamp,
                        timestamp: m.timestamp,
                        status: m.status
                    })
                );
        }
    }
    return fromJS(list);
}

export function parseSubscribedChannels(subscribedChannels = []) {
    return fromJS(subscribedChannels);
}

export function parseLocalUser(localUser) {
    return fromJS(localUser);
}
