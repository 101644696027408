/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { constants } from 'bandyersdkcommon';
import { intlShape, injectIntl } from 'react-intl';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import { CALL_TYPE_AUDIO_VIDEO } from '../../../../../../constants';
import Header from './components/Header';
import Messages from './components/Messages';
import Sender from './components/Sender';
import Style from '../channels/style.scss';

class Conversation extends Component {
    render() {
        const networkErrorLabel = this.props.intl.formatMessage({
            id: 'Error.network',
            defaultMessage: 'Network error'
        });
        return (
            <>
                <Header
                    toggleChat={this.props.toggleChat}
                    showCloseButton={this.props.showCloseButton}
                    createRoom={this.props.createCall}
                    participant={this.props.participant}
                    headerStyle={this.props.headerStyle}
                    selectedChannel={this.props.selectedChannel}
                    changeToChannel={this.props.changeToChannel}
                    selectCurrentChannel={this.props.selectCurrentChannel}
                    record={this.props.record}
                    widgetCallType={this.props.widgetCallType}
                    call={this.props.call}
                    changeView={this.props.changeView}
                    mode={this.props.mode}
                    focusWindowCall={this.props.focusWindowCall}
                    communication_center_state={this.props.communication_center_state}
                    twilio_socket_state={this.props.twilio_socket_state}
                />
                <div className={Style['main-container']} style={{ backgroundColor: '#fff' }}>
                    {this.props.twilio_socket_state !== constants.TWILIO_SOCKET_CONNECTED && (
                        <div className={Style.chatSockerError}>
                            <SignalWifiOffIcon fontSize="inherit" style={{ marginRight: '10px' }} />
                            {networkErrorLabel}
                        </div>
                    )}
                    <Messages
                        socketState={this.props.twilio_socket_state}
                        profileAvatar={this.props.profileAvatar}
                        messageStyle={this.props.messageStyle}
                        bodyStyle={this.props.bodyStyle}
                        fetchMessages={this.props.fetchMessages}
                    />
                    <Sender
                        socketState={this.props.twilio_socket_state}
                        sendMessage={this.props.sendMessage.bind(this, this.props.selectedChannel)}
                        placeholder={this.props.senderPlaceHolder}
                        disabledInput={this.props.disabledInput}
                        isTyping={this.props.isTyping}
                    />
                </div>
            </>
        );
    }
}

Conversation.propTypes = {
    sendMessage: PropTypes.func,
    isTyping: PropTypes.func,
    createCall: PropTypes.func,
    senderPlaceHolder: PropTypes.string,
    profileAvatar: PropTypes.string,
    toggleChat: PropTypes.func,
    removeChannel: PropTypes.func,
    showCloseButton: PropTypes.bool,
    disabledInput: PropTypes.bool,
    participant: PropTypes.instanceOf(Map),
    headerStyle: PropTypes.instanceOf(Map),
    messageStyle: PropTypes.instanceOf(Map),
    bodyStyle: PropTypes.instanceOf(Map),
    changeToChannel: PropTypes.func,
    selectCurrentChannel: PropTypes.func,
    selectedChannel: PropTypes.string,
    isCreatorOfChannel: PropTypes.func,
    record: PropTypes.bool,
    widgetCallType: PropTypes.string,
    call: PropTypes.instanceOf(Map),
    changeView: PropTypes.func,
    focusWindowCall: PropTypes.func,
    communication_center_state: PropTypes.string,
    twilio_socket_state: PropTypes.string,
    fetchMessages: PropTypes.func,
    intl: intlShape.isRequired
};

Conversation.defaultProps = {
    sendMessage: null,
    isTyping: null,
    createCall: null,
    senderPlaceHolder: null,
    profileAvatar: null,
    toggleChat: null,
    showCloseButton: true,
    disabledInput: false,
    participant: Map({}),
    headerStyle: Map({}),
    messageStyle: Map({}),
    bodyStyle: Map({}),
    selectCurrentChannel: null,
    changeToChannel: null,
    isCreatorOfChannel: null,
    selectedChannel: '',
    removeChannel: null,
    record: false,
    widgetCallType: CALL_TYPE_AUDIO_VIDEO,
    call: Map({}),
    changeView: null,
    focusWindowCall: null,
    communication_center_state: null,
    twilio_socket_state: null,
    fetchMessages: null
};

export default injectIntl(Conversation);
