import store from '../store';
import { widgetOperations } from '../../components/widget/redux';
import { socketStateOperations } from '../../components/socketStatus/redux';
import { usersDetailsOperations } from '../../components/usersDetails/redux';
import { callOperations } from '../../components/widget/components/main/components/call/redux';
import { validateCallTypeConfig } from '../../helpers/parseConfig';
import { buildChannelUniqueName, buildChannelListForClient, buildUnreadMessagesForClient } from '../../helpers/utils';
import {
    DIAL_OUT,
    DIAL_IN,
    CALL,
    CONVERSATION,
    CHANNELS,
    PUBLISHER_WEBCAM,
    IE_PLUGIN,
    CALL_WINDOW,
    ERROR
} from '../../constants';

export function addUserMessage(uniqueName, messageObj) {
    store.dispatch(widgetOperations.addUserMessage(uniqueName, messageObj));
}

export function addResponseMessage(uniqueName, messageObj) {
    store.dispatch(widgetOperations.addResponseMessage(uniqueName, messageObj));
}

export function toggleWidget() {
    store.dispatch(widgetOperations.toggleChat());
}

export function toggleInputDisabled() {
    store.dispatch(widgetOperations.toggleInputDisabled());
}

export function chatLogout() {
    return store.dispatch(widgetOperations.shutdown());
}

export function addChannel(user, options) {
    return store.dispatch(widgetOperations.addChannel(user, options));
}

export function removeChannel(user) {
    return store.dispatch(widgetOperations.removeChannel(user));
}

export function handleChannelAdded(channelCreated, isNew) {
    if (isNew) {
        store.dispatch(widgetOperations.hideWidget(false));
    }
    return store.dispatch(widgetOperations.fetchedSubscribedChannels(channelCreated));
}

export function handleChannelRemoved(channelRemoved) {
    store.dispatch(widgetOperations.removeChat(channelRemoved));
    return store.dispatch(widgetOperations.removeSubscribedChannel(channelRemoved));
}

export async function handleChanneSelectedAfterRemove(channelUniqueName) {
    const { behavior } = store.getState();
    if (
        behavior.has('selectedChannel')
        && behavior.get('selectedChannel') === channelUniqueName
        && behavior.get('view') === CONVERSATION
    ) {
        await store.dispatch(widgetOperations.changeView(CHANNELS));
        return store.dispatch(widgetOperations.setChannel(''));
    }
    return null;
}

export function updateChannelLastMessage(uniqueName, lastMessage) {
    return store.dispatch(widgetOperations.updateChannelLastMessage(uniqueName, lastMessage));
}

export function channelIsTyping(uniqueName, isTyping) {
    return store.dispatch(widgetOperations.channelIsTyping(uniqueName, isTyping));
}

export function initChat(config) {
    return store.dispatch(widgetOperations.initialize(config));
}

export function getChatInit() {
    return store.getState().behavior.get('chatInit');
}

export function getUsersDetailsCallPage(callParticipants) {
    const usersDetails = store.getState().usersDetails.get('usersDetails');
    const callPageUsersDetails = [];
    const participantsAlias = callParticipants.map(participant => participant.user.userAlias);
    participantsAlias.forEach((userAlias) => {
        callPageUsersDetails.push({ alias: userAlias, displayName: usersDetails.get(userAlias).get('formattedName') });
    });
    return callPageUsersDetails;
}

export function changeViewToDialOut() {
    return store.dispatch(widgetOperations.changeView(DIAL_OUT));
}

export function changeViewToDialIn() {
    return store.dispatch(widgetOperations.changeView(DIAL_IN));
}

export function changeViewToChannels() {
    return store.dispatch(widgetOperations.changeView(CHANNELS));
}

export function changeViewToChat() {
    return store.dispatch(widgetOperations.changeView(CONVERSATION));
}

export function changeViewToCallWindow() {
    return store.dispatch(widgetOperations.changeView(CALL_WINDOW));
}

export function resetToChannelView() {
    return store.dispatch(widgetOperations.resetToChannelView());
}

export function getWidgetMode() {
    const { behavior } = store.getState();
    return behavior.get('mode');
}

export function changeViewToCall() {
    return store.dispatch(widgetOperations.changeView(CALL));
}

export function changeViewToIEPlugin() {
    const { behavior } = store.getState();
    if (behavior.get('view') !== IE_PLUGIN) {
        return store.dispatch(widgetOperations.changeView(IE_PLUGIN));
    }
    return null;
}

export function handleStoppedCall(call) {
    return store.dispatch(callOperations.handleStoppedCall(call));
}

export function hangUpCall(call) {
    return store.dispatch(callOperations.hangUpTheCall(call));
}

export function declineCall(call) {
    return store.dispatch(callOperations.declineTheCall(call));
}

export function updateCall(call) {
    return store.dispatch(callOperations.updateCall(call));
}

export function changeMainVideo(mainVideo = PUBLISHER_WEBCAM) {
    return store.dispatch(callOperations.changeMainVideo(mainVideo));
}

export function toggleAudio() {
    return store.dispatch(callOperations.togglePublisherAudio());
}

export function toggleVideo() {
    return store.dispatch(callOperations.togglePublisherVideo());
}

export function handleErrorGum() {
    return store.dispatch(callOperations.errorGum());
}

export function handleMultipleSocket() {
    return store.dispatch(callOperations.multipleSocketError());
}

export function addFileToDownload(file) {
    return store.dispatch(callOperations.addFileToDownload(file));
}

export function handleErrorCallSocket() {
    return store.dispatch(callOperations.errorCallSocket());
}
export function setReconnectingCall(value) {
    return store.dispatch(callOperations.setReconnectingCall(value));
}

export function setCameraPermissionDenied(value) {
    return store.dispatch(callOperations.setCameraPermissionDenied(value));
}

export function setMicrophonePermissionDenied(value) {
    return store.dispatch(callOperations.setMicrophonePermissionDenied(value));
}

export function setMediaStream(mediaStream) {
    return store.dispatch(callOperations.setMediaStream(mediaStream));
}

export function updateRecordingInfo(recordingInfo) {
    return store.dispatch(callOperations.updateRecordingInfo(recordingInfo));
}

export function updateRecordingInRecordingInfo(recording) {
    return store.dispatch(callOperations.updateRecordingInRecordingInfo(recording));
}

export function setIsAdmin(isAdmin) {
    return store.dispatch(callOperations.setIsAdmin(isAdmin));
}

export function setError(error) {
    return store.dispatch(callOperations.setError(error));
}

export function setVerification(verification) {
    return store.dispatch(callOperations.setVerification(verification));
}

export function resetFile() {
    return store.dispatch(callOperations.resetFile());
}

export function setVirtualBackgrounds(virtualBackgrounds) {
    return store.dispatch(callOperations.setVirtualBackgrounds(virtualBackgrounds));
}

export function showWidget() {
    store.dispatch(widgetOperations.hideWidget(false));
}

export function hideWidget() {
    store.dispatch(widgetOperations.hideWidget(true));
}

export function openWidget() {
    store.dispatch(widgetOperations.expandWidget());
}

export function closeWidget() {
    store.dispatch(widgetOperations.closeWidget());
}

export function setExtensionUrl(extensionUrl) {
    store.dispatch(widgetOperations.setExtensionUrl(extensionUrl));
}

export function setUserStatusInChannel(uniqueName, status) {
    store.dispatch(widgetOperations.setUserStatusInChannel(uniqueName, status));
}

/** ************** DISPATCHER FOR ACTIVITIES ************************* */

export function updateMessageSent(message) {
    store.dispatch(widgetOperations.updateLastMessageSent(message));
}

export function updateMessageReceived(message) {
    store.dispatch(widgetOperations.updateLastMessageReceived(message));
}

export function getLastMessageReceived() {
    const { activity } = store.getState();
    return activity.get('lastMessageReceived');
}

export function getLastMessageSent() {
    const { activity } = store.getState();
    return activity.get('lastMessageSent');
}

export function setUnreadMessagesPerChannel(uniqueName, unreadMessage) {
    store.dispatch(widgetOperations.setUnreadMessagesPerChannel(uniqueName, unreadMessage));
}

export function getUnreadMessages() {
    const { activity } = store.getState();
    const unreadMessages = activity.get('unreadMessages').toJS();
    return buildUnreadMessagesForClient(unreadMessages);
}

/**
 * Used for activity reducer
 * @param uniqueName
 * @param unreadMessage
 */
export function updateUnreadMessagePerChannel(uniqueName, unreadMessage) {
    store.dispatch(widgetOperations.updateUnreadMessagePerChannel(uniqueName, unreadMessage));
}

export function getUserStatus(userAlias) {
    return store.dispatch(widgetOperations.getUserStatus(userAlias));
}

export function getUsersStatusList() {
    return store.dispatch(widgetOperations.getUsersStatusList());
}
export function setHaveChat(haveChat) {
    store.dispatch(widgetOperations.setHaveChat(haveChat));
}

/** ************** DISPATCHER WITH LOGIC FOR CALL METHODS ************************* */

export function createCall(participants, options) {
    const { behavior } = store.getState();
    const localUserAlias = behavior.get('localUser').get('userAlias');
    const parsedOptions = {
        record: options.record ? options.record : behavior.get('record'),
        callType: options.call_type ? validateCallTypeConfig(options.call_type) : behavior.get('widgetCallType')
    };
    const fullParticipants = participants.concat([localUserAlias]); // add the logged user to the participants list
    return store.dispatch(callOperations.createCall(buildChannelUniqueName(fullParticipants), parsedOptions));
}

export function joinCallURL(url) {
    store.dispatch(widgetOperations.hideWidget(false));
    store.dispatch(widgetOperations.expandWidget());
    return store.dispatch(callOperations.joinCallURL(url));
}

export function handleIncomingCall(call) {
    const { behavior } = store.getState();
    if (behavior.get('view') === CONVERSATION || behavior.get('view') === CHANNELS || behavior.get('view') === ERROR) {
        return store.dispatch(callOperations.handleIncomingCall(call));
    }
    throw new Error('The user is busy on another call');
}

export function handleDeclinedCall(call) {
    const { behavior } = store.getState();
    if (behavior.get('view') === DIAL_OUT) {
        return store.dispatch(callOperations.handleDeclinedCall(call));
    }
    throw new Error('The user is not in dial out');
}
export function resetCall() {
    return store.dispatch(callOperations.handleDeclinedCall());
}

export function selectChat(user) {
    const { behavior } = store.getState();
    if (behavior.get('view') === CHANNELS || behavior.get('view') === CONVERSATION) {
        if (behavior.has('localUser')) {
            return store.dispatch(
                widgetOperations.selectChannel(
                    buildChannelUniqueName([user, behavior.get('localUser').get('userAlias')])
                )
            );
        }
    }
    throw new Error('This action is not permitted in this state');
}

export async function composeMessage(user, text, send) {
    const { behavior } = store.getState();
    if (behavior.get('view') === CHANNELS || behavior.get('view') === CONVERSATION) {
        if (behavior.has('localUser')) {
            const selectedChannel = buildChannelUniqueName([user, behavior.get('localUser').get('userAlias')]);
            let result;
            if (!send) {
                result = await store.dispatch(widgetOperations.selectChannel(selectedChannel));
                await store.dispatch(widgetOperations.expandWidget()); // mandatory, if not open the 'new-message' element doesn't exist
                document.getElementById('new-message').value = text;
            } else {
                result = await store.dispatch(widgetOperations.sendMessage(selectedChannel, text));
            }
            return result;
        }
    }
    throw new Error('This action is not permitted in this state');
}

export function getChatList() {
    const { behavior, channels } = store.getState();
    if (behavior.has('localUser')) {
        return buildChannelListForClient(channels.toJS(), behavior.get('localUser').get('userAlias'));
    }
    throw new Error('This action is not permitted in this state');
}

/** ************** DISPATCHER FOR CALL ************************* */

export function publishWebcam(value) {
    store.dispatch(callOperations.publishWebcam(value));
}

export function publishScreen(value) {
    store.dispatch(callOperations.publishScreen(value));
}

export function publisherHasAudio() {
    store.dispatch(callOperations.publisherHasAudio());
}

export function publisherHasVideo() {
    store.dispatch(callOperations.publisherHasVideo());
}

export function selectAudioDevice(audioDevice) {
    store.dispatch(callOperations.selectAudioDevice(audioDevice));
}

export function selectVideoDevice(videoDevice) {
    store.dispatch(callOperations.selectVideoDevice(videoDevice));
}

export function getEnumerateDevices() {
    store.dispatch(callOperations.getEnumerateDevices());
}

export function setStreamEndedError(value) {
    store.dispatch(callOperations.setStreamEndedError(value));
}

export function setStreamFailedError(value) {
    store.dispatch(callOperations.setStreamFailedError(value));
}

export function setRemoteVideoMuted(value) {
    store.dispatch(callOperations.setRemoteVideoMuted(value));
}

/** ******************** dispatcher for socket state ****************** */

export function updateCommunicationCenterState(state) {
    store.dispatch(socketStateOperations.setCommunicationCenterState(state));
}
export function updateTwilioSocketState(state) {
    store.dispatch(socketStateOperations.setTwilioSocketState(state));
}
export function updateCallSocketState(state) {
    store.dispatch(socketStateOperations.setCallSocketState(state));
}

/** ******************** dispatcher for usersDetail ****************** */

export function setUsersDetails(users) {
    store.dispatch(usersDetailsOperations.updateUsersDetails(users));
}
