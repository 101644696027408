const CHANGE_FONT_FAMILY = 'CHANGE_FONT_FAMILY';
const CHANGE_HEADER_STYLE_BACKGROUND = 'CHANGE_HEADER_STYLE_BACKGROUND';
const CHANGE_HEADER_STYLE_COLOR = 'CHANGE_HEADER_STYLE_COLOR';
const CHANGE_HEADER_BUTTON_STYLE_BACKGROUND = 'CHANGE_HEADER_BUTTON_STYLE_BACKGROUND';
const CHANGE_HEADER_BUTTON_STYLE_COLOR = 'CHANGE_HEADER_BUTTON_STYLE_COLOR';
const CHANGE_BODY_STYLE_BACKGROUND = 'CHANGE_BODY_STYLE_BACKGROUND';
const CHANGE_BODY_STYLE_COLOR = 'CHANGE_BODY_STYLE_COLOR';
const CHANGE_LAUNCHER_STYLE_BACKGROUND = 'CHANGE_LAUNCHER_STYLE_BACKGROUND';
const CHANGE_LAUNCHER_STYLE_COLOR = 'CHANGE_LAUNCHER_STYLE_COLOR';
const CHANGE_MESSAGE_SENT_STYLE_BACKGROUND = 'CHANGE_MESSAGE_SENT_STYLE_BACKGROUND';
const CHANGE_MESSAGE_SENT_STYLE_COLOR = 'CHANGE_MESSAGE_SENT_STYLE_COLOR';
const CHANGE_MESSAGE_RECEIVED_STYLE_BACKGROUND = 'CHANGE_MESSAGE_RECEIVED_STYLE_BACKGROUND';
const CHANGE_MESSAGE_RECEIVED_STYLE_COLOR = 'CHANGE_MESSAGE_RECEIVED_STYLE_COLOR';
const CHANGE_DIAL_STYLE_BACKGROUND = 'CHANGE_DIAL_STYLE_BACKGROUND';
const CHANGE_DIAL_STYLE_COLOR = 'CHANGE_DIAL_STYLE_COLOR';
const CHANGE_CALL_STYLE_BACKGROUND = 'CHANGE_CALL_STYLE_BACKGROUND';

export default {
    CHANGE_FONT_FAMILY,
    CHANGE_HEADER_STYLE_BACKGROUND,
    CHANGE_HEADER_STYLE_COLOR,
    CHANGE_HEADER_BUTTON_STYLE_BACKGROUND,
    CHANGE_HEADER_BUTTON_STYLE_COLOR,
    CHANGE_BODY_STYLE_BACKGROUND,
    CHANGE_BODY_STYLE_COLOR,
    CHANGE_LAUNCHER_STYLE_BACKGROUND,
    CHANGE_LAUNCHER_STYLE_COLOR,
    CHANGE_MESSAGE_SENT_STYLE_BACKGROUND,
    CHANGE_MESSAGE_SENT_STYLE_COLOR,
    CHANGE_MESSAGE_RECEIVED_STYLE_BACKGROUND,
    CHANGE_MESSAGE_RECEIVED_STYLE_COLOR,
    CHANGE_DIAL_STYLE_BACKGROUND,
    CHANGE_DIAL_STYLE_COLOR,
    CHANGE_CALL_STYLE_BACKGROUND
};
