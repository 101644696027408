import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    IconButton,
    Tab,
    Tabs,
    AppBar,
    Divider,
    Fab,
    Box,
    Snackbar,
    SvgIcon
} from '@material-ui/core';
import { intlShape, injectIntl } from 'react-intl';
import { red, blue } from '@material-ui/core/colors';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Logger from '../../../../../../../logger';
import UploadItem from '../UploadItem';
import FileShareUtil from '../utils';
import DownloadItem from '../DownloadItem';
import SnackbarVariant from '../../call/snackbar/snackbarVariant';
import { ReactComponent as FileDownload } from '../../../../../../../assets/icon/File_Download.svg';
import { ReactComponent as FileUpload } from '../../../../../../../assets/icon/File_Upload.svg';
import Style from '../style.scss';

const axios = require('axios').default;

function TabContainer(props) {
    return <Typography component="div">{props.children}</Typography>;
}

createMuiTheme({
    palette: {
        primary: blue,
        secondary: red
    },
    overrides: { MuiTab: { wrapper: { flexDirection: 'row' } } }
});
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    appBar: {
        color: '#000000',
        background: 'transparent',
        boxShadow: 'none',
        display: 'flex',
        'flex-direction': 'row'
    },
    tabIcon: {
        marginRight: '8px',
        color: 'rgb(255, 109, 0)',
        marginBottom: '0px !important',
        fontSize: '30px'
    },
    notificationDownload: {
        top: '30px',
        left: '30px',
        position: 'absolute',
        minWidth: '15px',
        height: '15px',
        lineHeight: '15px',
        fontSize: '8px',
        borderRadius: '20px',
        '-webkit-transform': 'translateY(-50%)',
        transform: 'translateY(-50%)',
        transition: 'opacity 0.2s ease-in-out 0.1s',
        background: '#0c2e41',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: '#fff'
    }
});

const InlineTab = withStyles(() => ({
    root: { minWidth: 'auto', minHeight: 'auto' },
    wrapper: { flexDirection: 'row' }
}))(Tab);

const backdrop = { position: 'absolute' };

const DialogTitle = withStyles(styles)(props => {
    const { children, headerStyle, downloadNotification, classes, onClose, handleChange, value, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <AppBar className={classes.appBar} position="static">
                <Box display="flex" alignItems="center" style={{ width: '100%' }}>
                    <Tabs
                        style={{ flexGrow: 1 }}
                        TabIndicatorProps={{ style: { background: headerStyle.get('button').get('background') } }}
                        value={value}
                        onChange={handleChange}
                    >
                        <InlineTab
                            icon={
                                <SvgIcon
                                    className={classes.tabIcon}
                                    style={{ color: headerStyle.get('button').get('background') }}
                                    component={FileUpload}
                                />
                            }
                            label="Upload"
                        />
                        <InlineTab
                            icon={
                                <>
                                    <SvgIcon
                                        className={classes.tabIcon}
                                        component={FileDownload}
                                        style={{ color: headerStyle.get('button').get('background') }}
                                    />
                                    {!!downloadNotification && (
                                        <span className={classes.notificationDownload}>{downloadNotification}</span>
                                    )}
                                </>
                            }
                            label="Download"
                        />
                    </Tabs>

                    {children}
                </Box>
            </AppBar>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class FileShare extends PureComponent {
    constructor(props) {
        super(props);
        const { filesUploaded } = this.props;
        this.state = {
            value: 0,
            filesUploaded,
            maxFileSize: false,
            genericError: false
        };
        this._util = new FileShareUtil();
        this._L = new Logger('FileShare');
    }

    componentDidUpdate(prevProps, prevState) {
        const { downloadNotification, setDownloadNotification, filesUploaded } = this.props;
        const { value } = this.state;
        if (prevState.value !== value && value === 0 && !!downloadNotification) {
            setDownloadNotification(0);
        }
        if (prevProps.filesUploaded !== filesUploaded && filesUploaded !== this.state.filesUploaded) {
            this.setState({ filesUploaded });
        }
    }

    componentWillUnmount() {
        const { downloadNotification, setDownloadNotification } = this.props;
        if (downloadNotification) {
            setDownloadNotification(0);
        }
    }

    closeFileShare = () => {
        const { toggleFileShare } = this.props;
        toggleFileShare();
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    fileUpload = async file => {
        if (!this._util.validateFile(file)) {
            // reset input and revokeObject
            this.upload.value = '';
            URL.revokeObjectURL(file);
            this.setState({ maxFileSize: true });
            return;
        }
        let { filesUploaded } = this.state;
        const { call, localUser, addFileUploaded, intl } = this.props;
        const policy = await this._util.getUploadPolicy(call).catch(err => {
            this.setState({ genericError: true });
        });
        const splitFileName = file.name.split('.');
        const fileExtension = splitFileName.length > 1 ? `.${splitFileName.pop()}` : '';
        const cancelFunction = this._util.cancelFunction();
        const fileName = `${policy.key.split('/').pop()}${fileExtension}`;
        let item = Map({
            name: fileName,
            url: `${policy.url}${fileExtension}`,
            progress: 0,
            cancelFunction,
            withCredentials: policy.withCredentials
        });
        filesUploaded = filesUploaded.set(fileName, item);
        this.setState({ filesUploaded });
        this._util
            .uploadFile(policy, file, this.progress, cancelFunction)
            .then(res => {
                this._util.emitFileUploadEvent(`${policy.url}${fileExtension}`, localUser.get('userAlias'));
                item = item.set('progress', 100);
                addFileUploaded(item);
                /* Need for safari progress, safari no trigger progress callback when the upload is completed */
                filesUploaded = filesUploaded.set(fileName, item);
                this.setState({ filesUploaded });
                this._L.debug('Upload success');
            })
            .catch(err => {
                let fu = this.state.filesUploaded;
                if (axios.isCancel(err)) {
                    // handle user dismiss
                    this._L.debug('Request canceled - File name: ', err.message);
                    const name = err.message;
                    fu = fu.delete(name);
                    this.setState({ filesUploaded: fu });
                } else {
                    const genericErrorLabel = intl.formatMessage({
                        id: 'UploadFile.GenericError',
                        defaultMessage: 'Error during upload'
                    });
                    item = item.set('error', { message: genericErrorLabel, file });
                    fu = fu.set(fileName, item);
                    addFileUploaded(item);
                    this.setState({ filesUploaded: fu });
                    this._L.error('Unable to upload your file', err);
                }
            });
        // reset input and revokeObject
        this.upload.value = '';
        URL.revokeObjectURL(file);
    };

    cancelUpload = (name, cancelFunction) => {
        const { removeFileUploaded } = this.props;
        cancelFunction.cancel(name);
        removeFileUploaded(name);
    };

    removeItem = name => {
        let { filesUploaded } = this.state;
        const { removeFileUploaded } = this.props;
        filesUploaded = filesUploaded.delete(name);
        this.setState({ filesUploaded });
        removeFileUploaded(name);
    };

    progress = (progressEvent, fileName) => {
        let { filesUploaded } = this.state;
        const { addFileUploaded } = this.props;
        let file = filesUploaded.get(fileName);
        file = file.set('progress', parseInt((progressEvent.loaded / progressEvent.total) * 100, 10));
        filesUploaded = filesUploaded.set(fileName, file);
        this.setState({ filesUploaded });
        addFileUploaded(file);
    };

    render() {
        const {
            showFileShare,
            container,
            headerStyle,
            filesToDownload,
            updateDownloadFile,
            networkError,
            intl,
            downloadNotification,
            setDownloadNotification
        } = this.props;
        const { value, filesUploaded, maxFileSize, genericError } = this.state;
        const maxFileSizeLabel = intl.formatMessage({
            id: 'UploadFile.MaxFileSize',
            defaultMessage: 'Max file size exceed'
        });
        const genericErrorLabel = intl.formatMessage({
            id: 'UploadFile.GenericError',
            defaultMessage: 'Error during upload'
        });
        const UploadFile = withStyles(() => ({
            root: {
                color: 'rgb(255, 109, 0)',
                background: headerStyle.get('button').get('color'),
                boxShadow: 'none',
                '&:hover': {
                    background: headerStyle.get('button').get('background'),
                    color: headerStyle.get('button').get('color')
                }
            }
        }))(Fab);
        return (
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={showFileShare}
                container={container}
                style={{ position: 'absolute' }}
                onClose={this.closeFileShare}
                fullWidth
                maxWidth="xl"
                className={Style.fileShareDialog}
                BackdropProps={{ style: backdrop }}
                PaperProps={{
                    style: { height: '90%', margin: 20, maxHeight: '90%', width: '100%', maxWidth: '600px' }
                }}
            >
                <DialogTitle
                    downloadNotification={downloadNotification}
                    headerStyle={headerStyle}
                    value={value}
                    handleChange={this.handleChange}
                >
                    {value === 0 && (
                        <Box>
                            <input
                                id="filePicker"
                                type="file"
                                ref={ref => (this.upload = ref)}
                                onChange={e => this.fileUpload(e.target.files[0])}
                                style={{ display: 'none' }}
                            />
                            <UploadFile
                                style={{ color: headerStyle.get('button').get('background') }}
                                disabled={networkError}
                                size="small"
                                onClick={() => this.upload.click()}
                                aria-label="upload"
                            >
                                <AddIcon />
                            </UploadFile>
                        </Box>
                    )}
                </DialogTitle>
                <Divider />
                <DialogContent style={{ position: 'relative', overflow: 'hidden', padding: '0px', overflowY: 'auto' }}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        open={genericError}
                        autoHideDuration={8000}
                        onClose={() => {
                            this.setState({ genericError: false });
                        }}
                        style={{ fontSize: '12px', position: 'absolute' }}
                    >
                        <SnackbarVariant noAction variant="error" message={genericErrorLabel} />
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        open={maxFileSize}
                        autoHideDuration={8000}
                        onClose={() => {
                            this.setState({ maxFileSize: false });
                        }}
                        style={{ fontSize: '12px', position: 'absolute' }}
                    >
                        <SnackbarVariant noAction variant="error" message={maxFileSizeLabel} />
                    </Snackbar>
                    {value === 0 && (
                        <>
                            <TabContainer className={Style.uploadContainer}>
                                {filesUploaded
                                    .valueSeq()
                                    .reverse()
                                    .map(item => (
                                        <UploadItem
                                            key={item.get('name')}
                                            fileItem={item}
                                            cancelUpload={this.cancelUpload}
                                            fileUpload={this.fileUpload}
                                            removeItem={this.removeItem}
                                            networkError={networkError}
                                        />
                                    ))}
                            </TabContainer>
                        </>
                    )}
                    {value === 1 && (
                        <TabContainer>
                            {filesToDownload
                                .valueSeq()
                                .reverse()
                                .map((item, index) => (
                                    <DownloadItem
                                        key={item.get('name')}
                                        fileItem={item}
                                        progress={this.progress}
                                        updateDownloadFile={updateDownloadFile}
                                        newFile={index < downloadNotification}
                                        setDownloadNotification={setDownloadNotification}
                                    />
                                ))}
                        </TabContainer>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button color="primary" onClick={this.closeFileShare} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
FileShare.propTypes = {
    showFileShare: PropTypes.bool,
    container: PropTypes.shape({ component: PropTypes.instanceOf(React.Component) }),
    toggleFileShare: PropTypes.func,
    headerStyle: PropTypes.instanceOf(Map),
    call: PropTypes.string,
    localUser: PropTypes.instanceOf(Map),
    filesToDownload: PropTypes.instanceOf(Map),
    filesUploaded: PropTypes.instanceOf(Map),
    addFileUploaded: PropTypes.func,
    updateDownloadFile: PropTypes.func,
    downloadNotification: PropTypes.number,
    setDownloadNotification: PropTypes.func,
    networkError: PropTypes.bool,
    intl: intlShape.isRequired,
    removeFileUploaded: PropTypes.func
};

FileShare.defaultProps = {
    showFileShare: true,
    container: null,
    toggleFileShare: null,
    headerStyle: Map([]),
    call: null,
    localUser: Map([]),
    filesToDownload: Map([]),
    filesUploaded: Map([]),
    addFileUploaded: null,
    updateDownloadFile: null,
    downloadNotification: 0,
    setDownloadNotification: null,
    networkError: false,
    removeFileUploaded: null
};

export default injectIntl(FileShare);
