import actions from './types';

export function setFontFamily(fontFamily) {
    return {
        type: actions.CHANGE_FONT_FAMILY,
        fontFamily
    };
}

export function setHeaderStyleBackground(style) {
    return {
        type: actions.CHANGE_HEADER_STYLE_BACKGROUND,
        style
    };
}

export function setHeaderStyleColor(style) {
    return {
        type: actions.CHANGE_HEADER_STYLE_COLOR,
        style
    };
}

export function setHeaderButtonStyleBackground(style) {
    return {
        type: actions.CHANGE_HEADER_BUTTON_STYLE_BACKGROUND,
        style
    };
}

export function setHeaderButtonStyleColor(style) {
    return {
        type: actions.CHANGE_HEADER_BUTTON_STYLE_COLOR,
        style
    };
}

export function setBodyStyleBackground(style) {
    return {
        type: actions.CHANGE_BODY_STYLE_BACKGROUND,
        style
    };
}

export function setBodyStyleColor(style) {
    return {
        type: actions.CHANGE_BODY_STYLE_COLOR,
        style
    };
}

export function setLauncherStyleBackground(style) {
    return {
        type: actions.CHANGE_LAUNCHER_STYLE_BACKGROUND,
        style
    };
}

export function setLauncherStyleColor(style) {
    return {
        type: actions.CHANGE_LAUNCHER_STYLE_COLOR,
        style
    };
}

export function setMessageSentStyleBackground(style) {
    return {
        type: actions.CHANGE_MESSAGE_SENT_STYLE_BACKGROUND,
        style
    };
}

export function setMessageSentStyleColor(style) {
    return {
        type: actions.CHANGE_MESSAGE_SENT_STYLE_COLOR,
        style
    };
}

export function setMessageReceivedStyleBackground(style) {
    return {
        type: actions.CHANGE_MESSAGE_RECEIVED_STYLE_BACKGROUND,
        style
    };
}

export function setMessageReceivedStyleColor(style) {
    return {
        type: actions.CHANGE_MESSAGE_RECEIVED_STYLE_COLOR,
        style
    };
}

export function setDialStyleBackground(style) {
    return {
        type: actions.CHANGE_DIAL_STYLE_BACKGROUND,
        style
    };
}

export function setDialStyleColor(style) {
    return {
        type: actions.CHANGE_DIAL_STYLE_COLOR,
        style
    };
}

export function setCallStyleBackground(style) {
    return {
        type: actions.CHANGE_CALL_STYLE_BACKGROUND,
        style
    };
}
