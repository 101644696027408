import Logger from '../../logger/index';
import {
    CHANNEL_NOT_FOUND,
    MEMBER_ALREADY_EXISTS,
    NO_MEMBER_SELECTED,
    parseTwilioErrors
} from '../../helpers/errorHandler';

import { setUnreadMessagesPerChannel } from '../../store/actions/dispatcher';

import { parseAttributesMessage, buildParticipantFromChannelUniqueName } from '../../helpers/utils';

import { MESSAGE_SENDER, MESSAGES_STATUS } from '../../constants';

class Util {
    constructor(client) {
        this._client = client;
    }

    setAllReadMessages(uniqueName) {
        setUnreadMessagesPerChannel(uniqueName, 0);
        return this._client._twilioChatClient.channels[uniqueName].setAllMessagesConsumed();
    }

    /**
     * This fn gets for every members the last unconsumed message. It returns an object with the alias has keys and
     * an object composed by: usr_123456:
     * {
     *      identity: 'usr_123456',
     *      lastConsumedMessageIndex: Number',
     *      lastConsumptionTimestamp: Date,
     *      channel: 'usr_123456#usr_890',
     * }
     * @param uniqueName
     * @returns {Promise.<TResult>|*}
     */
    async getMemberLastUnconsumedMessage(uniqueName) {
        if (this._client._twilioChatClient.channels[uniqueName]) {
            const members = await this._client._twilioChatClient.channels[uniqueName].getMembers();
            const toReturn = {};
            for (let i = 0; i < members.length; i++) {
                toReturn[members[i].identity] = {
                    identity: members[i].identity,
                    lastConsumedMessageIndex: members[i].lastConsumedMessageIndex,
                    lastConsumptionTimestamp: members[i].lastConsumptionTimestamp,
                    channel: members[i].channel.state.uniqueName
                };
            }
            return toReturn;
        }
        throw new Error(CHANNEL_NOT_FOUND);
    }

    /**
     * This is a utility fn that creates the dialog structure for the chat.controller. The structure is the following:
     * [{
     * message: 'ciao',
     *      timestamp: new Date(),
     *      who: 'usr_1235',
     *      type: 'text', or 'media'
     *      index: 1,
     *      status: 'send', or 'read'
     *      readTimestamp: Date,
     *      media: media,
     *
     * },  ...]
     * @param messages
     * @param members
     * @param uniqueName
     * @returns {Array}
     */
    buildDialogObj({ messages, members, channel }) {
        const dialog = [];
        for (const m of messages) {
            const toAdd = {
                message: m.body,
                timestamp: m.timestamp,
                who: m.author,
                type: m.type,
                index: m.index,
                sender: MESSAGE_SENDER.RESPONSE
            };
            if (toAdd.who === this._client._localUserAlias) {
                const messageStatus = Util._buildMessageStatus(m, members, channel);
                toAdd.status = messageStatus.status;
                toAdd.readTimestamp = messageStatus.readTimestamp;
                toAdd.sender = MESSAGE_SENDER.CLIENT;
            }
            if (m.type === 'media') {
                toAdd.media = m.media;
            }
            toAdd.component = parseAttributesMessage(m.state.attributes);
            dialog.push(toAdd);
        }
        return dialog;
    }

    /**
     * This fn build the message status for the chat. It only works for the one to one chat.
     * @param message
     * @param members all the members of the channel
     * @returns {{}}
     * @private
     */
    static _buildMessageStatus(message, members, channel) {
        const toReturn = {};
        if (channel) {
            let participants = null;
            if (channel.state.attributes.participants) {
                participants = channel.state.attributes;
            } else {
                // fallback to old attributes structure
                participants = channel.state.attributes.users;
            }
            const member = members[participants[0]];
            if (!member) {
                toReturn.status = 'send';
                toReturn.readTimestamp = null;
                return toReturn;
            }
            toReturn.status = message.index > member.lastConsumedMessageIndex ? 'send' : 'read';
            toReturn.readTimestamp = null;
            if (message.index === member.lastConsumedMessageIndex) {
                toReturn.readTimestamp = member.lastConsumptionTimestamp;
            }
            return toReturn;
        }
        throw new Error(CHANNEL_NOT_FOUND);
    }

    /**
     * Makes a rest request to Bandyer Connection server in order to fetch all the user information
     * such as firstname,lastname, email and image.
     * @param userAlias
     * @returns {Promise.<TResult>|*}
     */
    async loadUsersInfo(userAlias) {
        const userResult = await this._client.bandyerWebSdk.getUserObject(userAlias);
        if (userResult.user && userResult.user.userAlias) {
            this._client._fetchedUsers[userResult.user.userAlias] = userResult.user;
            return userResult.user;
        }
        return null;
    }

    static async getChannelMessageInfo(channel, localUserAlias) {
        try {
            const toReturn = {};
            toReturn.lastMessage = {};
            if (channel) {
                const unreadMessage = await Util.getUnconsumedMessages(channel, localUserAlias);
                setUnreadMessagesPerChannel(channel.state.uniqueName, unreadMessage);
                toReturn.unreadMessage = unreadMessage;
                const message = await Util._getLastMessage(channel);
                if (message && message.items.length) {
                    toReturn.lastMessage.author = message.items[0].author;
                    if (message.items[0].type === 'media') {
                        toReturn.lastMessage.message = 'Media message';
                    } else {
                        toReturn.lastMessage.message = message.items[0].body;
                    }
                    toReturn.lastMessage.timestamp = message.items[0].timestamp;
                } else {
                    toReturn.lastMessage.timestamp = channel.dateCreated;
                }
                return toReturn;
            }
        } catch (err) {
            Logger.warn('[getChannelMessageInfo]', err);
        }
        throw new Error('NO_CHANNEL_SELECTED');
    }

    /**
     * This fn calls the Twilio fn getMessages in order to retrieve the last message for the
     * channel requested
     * @returns {Promise.<T>|Promise|*}
     * @private
     * @param channel
     */
    static _getLastMessage(channel) {
        if (channel) {
            return channel.getMessages(1).then(message => {
                if (message.items.length) {
                    return message;
                }
                return null;
            });
        }
        return null;
    }

    static joinChannel(channel) {
        return channel.join().catch(err => {
            const errorCode = parseTwilioErrors(err);
            // il throw error lo posso fare anche nell'index delle actions.
            switch (errorCode) {
                case MEMBER_ALREADY_EXISTS:
                    return channel;
                default:
                    // qui dovrei sparare un errore generico?
                    throw new Error('joinChannel failed');
            }
        });
    }

    static _removeChannel(channel) {
        return channel.delete();
    }

    static addMemberToChannel(userAlias = null, channel) {
        if (userAlias) {
            Logger.debug('[addMemberToChannel] - userAlias', userAlias);
            return channel.add(userAlias);
        }
        return Promise.reject(new Error(NO_MEMBER_SELECTED));
    }

    /**
     * This fn calls the Twilio fn getUnconsumedMessagesCount in order to retrieve the unconsumed messages for the
     * channel requested
     * @param uniqueName of the channel
     * @returns {Promise} unconsumed message for the channel
     */
    static getUnconsumedMessages(channel, localUserAlias) {
        if (channel) {
            return channel.getMembers().then(members => {
                for (const m of members) {
                    if (m.lastConsumedMessageIndex === null && m.identity === localUserAlias) {
                        return channel.getMessagesCount();
                    }
                }
                return channel.getUnconsumedMessagesCount();
            });
        }
        return Promise.resolve(0);
    }

    static buildMessageObj(message, sender) {
        return {
            text: message.state.body,
            sender,
            readTimestamp: null,
            timestamp: message.state.timestamp,
            status: MESSAGES_STATUS.SENT
        };
    }

    static buildIsTypingObj(channel, localUserAlias) {
        if (channel && localUserAlias) {
            return {
                userAlias: localUserAlias,
                chat: channel.state.uniqueName,
                participants: buildParticipantFromChannelUniqueName(channel.state.uniqueName)
            };
        }
        return null;
    }

    static buildMessageEventObj(message, localUserAlias) {
        if (message && localUserAlias) {
            return {
                id: message.state.sid,
                chat: message.channel.state.uniqueName,
                participants: buildParticipantFromChannelUniqueName(message.channel.state.uniqueName),
                text: message.state.body,
                sender: message.state.author,
                timestamp: message.state.timestamp
            };
        }
        return null;
    }

    static async getMessageFromCurrentChannel(channel, msgIdx, localUserAlias) {
        const messages = await channel.getMessages();
        let toReturn = null;
        for (const m of messages.items) {
            if (m.state.index === msgIdx) {
                toReturn = Util.buildMessageEventObj(m, localUserAlias);
                break;
            }
        }
        return toReturn;
    }
}

export default Util;
