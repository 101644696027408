import { Map } from 'immutable';
import * as actionTypes from '../actions/actionTypes';

const initialState = Map({
    lastMessageReceived: null,
    lastMessageSent: null,
    unreadMessages: Map({})
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_MESSAGE_RECEIVED:
            return state.update('lastMessageReceived', () => action.message);
        case actionTypes.UPDATE_MESSAGE_SENT:
            return state.update('lastMessageSent', () => action.message);
        case actionTypes.SET_UNREAD_MESSAGE_PER_CHANNEL:
            return state.setIn(['unreadMessages', action.channelUniqueName], action.unreadMessages);
        default:
            return state;
    }
}
