import * as Sentry from '@sentry/browser';

const packageName = require('../../package.json').name;
const version = require('../../package.json').version;

function initSentry({ environment, userAlias }) {
    if (environment !== 'development') {
        Sentry.init({
            dsn: 'https://7db858abdc994acba39aa228b3a8350a@sentry.io/1292132',
            environment,
            release: `${packageName} ${version}`
        });
        Sentry.configureScope(scope => {
            scope.setUser({ id: userAlias });
        });
    }
}

export { initSentry };
