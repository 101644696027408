import { fromJS, List, Map } from 'immutable';

import { parseSubscribedChannels } from './helper';
import actionTypes from '../../components/widget/redux/types';

const initialState = List([]);

const sortByLastMessage = (i, j) => {
    if (i.has('lastMessage') && j.has('lastMessage')) {
        const iTimestamp = new Date(i.getIn(['lastMessage', 'timestamp']));
        const jTimestamp = new Date(j.getIn(['lastMessage', 'timestamp']));
        if (iTimestamp > jTimestamp) {
            return -1;
        }
        return 1;
    }
    return -1;
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SUBSCRIBED_CHANNELS_FETCHED: {
            return state.push(parseSubscribedChannels(action.subscribedChannels)).sort(sortByLastMessage);
        }
        case actionTypes.REMOVE_CHANNEL: {
            return state.filter(element => element.get('uniqueName') !== action.selectedChannel);
        }
        case actionTypes.UPDATE_LAST_MESSAGE: {
            return state
                .update(
                    state.findIndex(item => item.get('uniqueName') === action.uniqueName),
                    item => item.set('lastMessage', fromJS(action.lastMessage))
                )
                .sort(sortByLastMessage);
        }
        case actionTypes.UPDATE_UNREAD_MESSAGE_PER_CHANNEL: {
            return state.update(
                state.findIndex(item => item.get('uniqueName') === action.uniqueName),
                item => item.set('unreadMessage', action.unreadMessage)
            );
        }
        case actionTypes.IS_TYPING: {
            return state.update(
                state.findIndex(item => item.get('uniqueName') === action.uniqueName),
                item => item.set('isTyping', fromJS(action.isTyping))
            );
        }
        case actionTypes.SET_USER_STATUS_PER_CHANNEL: {
            const index = state.findIndex(item => item.get('uniqueName') === action.uniqueName);
            if (index !== -1) {
                return state.update(index, item => item.set('status', fromJS(action.status)));
            }
            return state;
        }
        case actionTypes.SUBSCRIBED_CHANNELS_FETCHED_NO_RESULTS:
        default:
            return state;
    }
}
