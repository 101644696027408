import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Fab, IconButton, CircularProgress, StylesProvider } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Constants from 'bandyersdkcommon/dist/src/constants';
import { MESSAGE_SENDER } from '../../../../../../../../constants';
import Style from './style.scss';

class Messages extends Component {
    constructor(props) {
        super(props);
        const { channels, selectedChannel } = this.props;
        let notification = 0;
        channels.forEach(c => {
            if (c.get('uniqueName') === selectedChannel) {
                notification = c.get('unreadMessage');
            }
        });
        this.state = {
            showFastForward: false,
            countMessage: notification,
            scrollToUnReadMessages: false,
            viewScrollNumber: true,
            isFetching: false
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.isFetching = false;
    }

    componentDidMount() {
        if (this.state.countMessage === 0) this.scrollToBottom();
        else {
            this.setState({ scrollToUnReadMessages: true });
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.socketState !== this.props.socketState &&
            this.props.socketState === Constants.TWILIO_SOCKET_CONNECTED &&
            this.state.isFetching === true
        ) {
            const messagesDiv = document.getElementById(Style.messages);
            const scrollBefore = messagesDiv.scrollHeight;
            const index = this.props.messages.first().get('index');
            await this.props.fetchMessages(this.props.selectedChannel, index - 1);
            const scrollAfter = messagesDiv.scrollHeight;
            messagesDiv.scrollTop = scrollAfter - scrollBefore;
            this.resetFetching();
        }
        if (
            prevProps.socketState === this.props.socketState &&
            prevProps.messages !== this.props.messages &&
            this.state.isFetching === false
        ) {
            const count = this.state.countMessage + 1;
            if (snapshot || this.props.messages.last().get('sender') === MESSAGE_SENDER.CLIENT) {
                this.scrollToBottom();
            } else if (this.state.showFastForward) {
                this.setState({ countMessage: count, viewScrollNumber: true });
            } else {
                this.scrollToBottom();
            }
        }
        if (this.state.scrollToUnReadMessages === true) {
            const unReadMessageLabel = document.getElementById(Style.unReadMessage);
            const messagesDiv = document.getElementById(Style.messages);
            unReadMessageLabel.scrollIntoView();
            messagesDiv.scrollTop -= 10;
            this.setState({
                scrollToUnReadMessages: false,
                viewScrollNumber: false
            });
        }
        if (prevProps.messages !== this.props.messages && this.isFetching === true) {
            this.resetFetching();
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        const messagesDiv = document.getElementById(Style.messages);
        if (messagesDiv.scrollHeight - messagesDiv.scrollTop - messagesDiv.clientHeight <= 0) {
            return true;
        }
        return false;
    }

    handleScroll = async () => {
        const { scrollToUnReadMessages } = this.state;
        if (this.isFetching || scrollToUnReadMessages) {
            return;
        }
        const messagesDiv = document.getElementById(Style.messages);
        const { fetchMessages, selectedChannel, messages } = this.props;
        const index = messages.first().get('index');
        const scrollBefore = messagesDiv.scrollHeight;
        if (messagesDiv.scrollHeight - messagesDiv.scrollTop - messagesDiv.clientHeight > 1) {
            this.setState({ showFastForward: true });
            if (messagesDiv.scrollTop < 100 && index !== 0) {
                this.isFetching = true;
                this.setState({ isFetching: this.isFetching });
                if (this.props.socketState === Constants.TWILIO_SOCKET_CONNECTED) {
                    await fetchMessages(selectedChannel, index - 1);
                    const scrollAfter = messagesDiv.scrollHeight;
                    messagesDiv.scrollTop = scrollAfter - scrollBefore;
                }
            }
        } else {
            this.setState({
                showFastForward: false,
                countMessage: 0
            });
        }
    };

    scrollToBottom = () => {
        const messagesDiv = document.getElementById(Style.messages);
        messagesDiv.scrollTop = messagesDiv.scrollHeight;
        this.setState({ countMessage: 0 });
    };

    getComponentToRender = message => {
        const ComponentToRender = message.get('component');
        if (message.get('type') === 'component') {
            return <ComponentToRender {...message.get('props')} messageStyle={this.props.messageStyle} />;
        }
        return <ComponentToRender message={message} messageStyle={this.props.messageStyle} />;
    };

    unreadMessagesLabel = (index, size) => {
        const unReadText = <FormattedMessage id="Conversation.unreadMessages" defaultMessage="Un-read messages" />;
        if (size - this.state.countMessage == index) {
            return (
                <div id={Style.unReadMessage} className={Style.unReadMessage}>
                    {unReadText}
                </div>
            );
        }
    };

    resetFetching = () => {
        this.isFetching = false;
        this.setState({ isFetching: this.isFetching });
    };

    render() {
        const { showFastForward, countMessage, viewScrollNumber, isFetching } = this.state;
        return (
            <div
                id={Style.messages}
                className={Style['messages-container']}
                onScroll={this.handleScroll}
                style={{
                    background: this.props.bodyStyle.get('background'),
                    color: this.props.bodyStyle.get('color')
                }}
            >
                {isFetching && (
                    <div className={Style.fetcherLoader}>
                        <CircularProgress className={Style.circularProgress} size={15} />
                    </div>
                )}
                {this.props.messages.map((message, index) => (
                    <div className={Style.message} key={index}>
                        {this.unreadMessagesLabel(index, this.props.messages.size)}
                        {this.props.profileAvatar && message.get('showAvatar') && (
                            <img src={this.props.profileAvatar} className={Style.avatar} alt="profile" />
                        )}
                        {this.getComponentToRender(message)}
                    </div>
                ))}

                {showFastForward && (
                    <IconButton
                        size="small"
                        aria-label="delete"
                        className={Style.arrowButton}
                        onClick={this.scrollToBottom}
                    >
                        {!!countMessage && viewScrollNumber && <div className={Style.notification}>{countMessage}</div>}
                        <ExpandMoreIcon className="arrow" />
                    </IconButton>
                )}
            </div>
        );
    }
}

Messages.propTypes = {
    messages: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
    profileAvatar: PropTypes.string,
    headerStyle: PropTypes.instanceOf(Map),
    messageStyle: PropTypes.instanceOf(Map),
    bodyStyle: PropTypes.instanceOf(Map),
    selectedChannel: PropTypes.string,
    channels: PropTypes.instanceOf(List),
    fetchMessages: PropTypes.func,
    socketState: PropTypes.string
};

Messages.defaultProps = {
    messages: List([]),
    profileAvatar: '',
    headerStyle: Map({}),
    messageStyle: Map({}),
    bodyStyle: Map({}),
    selectedChannel: '',
    channels: List([]),
    fetchMessages: null,
    socketState: null
};

export default connect(store => ({
    selectedChannel: store.behavior.get('selectedChannel'),
    channels: store.channels,
    messages: store.messages.get(store.behavior.get('selectedChannel')),
    messageStyle: store.styles.get('message')
}))(Messages);
