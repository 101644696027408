import React, { PureComponent } from 'react';
import { MESSAGE_SENDER, MESSAGE_PROP_TYPES } from 'constants';

class Activity extends PureComponent {
    render() {
        return (
            <div className={`activity-content ${this.props.message.get('sender')}`}>
                {this.props.message.get('sender') === MESSAGE_SENDER.CLIENT ? (
                    <div className="message-text">You made a call</div>
                ) : (
                    <div className="message-text">You received a call</div>
                )}
            </div>
        );
    }
}

Activity.propTypes = {
    message: MESSAGE_PROP_TYPES.MESSAGE
};

export default Activity;
