import { Map } from 'immutable';
import actionTypes from './types';
import { constants } from 'bandyersdkcommon';

const initialState = Map({
    communication_center_state: constants.COMMUNICATION_CENTER_DISCONNECTED,
    twilio_socket_state: 'NOT_AUTHENTICATED',
    call_socket_state: constants.CALL_SOCKET_DISCONNECTED
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_COMMUNICATION_CENTER_STATE: {
            return state.update('communication_center_state', () => action.state);
        }
        case actionTypes.UPDATE_TWILIO_SOCKET_STATE: {
            return state.update('twilio_socket_state', () => action.state);
        }
        case actionTypes.UPDATE_CALL_SOCKET_STATE: {
            return state.update('call_socket_state', () => action.state);
        }
        default:
            return state;
    }
}
