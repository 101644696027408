import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import Style from './style.scss';
import Main from './components/main/MainContainer';
import Launcher from './components/launcher/LauncherContainer';

const buildClassName = props => {
    let className =
        props.isCalling || props.showChat ? Style['widget-container'] : `${Style['widget-container']} ${Style.resized}`;
    if (props.fullScreenMode) {
        className = className.concat(' ', 'full-screen');
    }
    if (props.hideWidget) {
        className = className.concat(' ', Style.hidden);
    }
    return className;
};

const generateClassName = createGenerateClassName({ seed: 'Bandyer' });

const WidgetLayout = props => (
    <StylesProvider generateClassName={generateClassName} injectFirst>
        <div className={buildClassName(props)} style={{ fontFamily: props.fontFamily }}>
            {(props.isCalling || props.showChat) && (
                <Main
                    sendMessage={props.onSendMessage}
                    toggleChat={props.onToggleConversation}
                    showChat={props.showChat}
                    disabledInput={props.disabledInput}
                    toggleFullScreen={props.toggleFullScreen}
                />
            )}
            {<Launcher toggle={props.onToggleConversation} launcherStyle={props.launcherStyle} />}
        </div>
    </StylesProvider>
);

WidgetLayout.propTypes = {
    onSendMessage: PropTypes.func,
    onToggleConversation: PropTypes.func,
    showChat: PropTypes.bool,
    disabledInput: PropTypes.bool,
    isCalling: PropTypes.number,
    toggleFullScreen: PropTypes.func,
    fontFamily: PropTypes.string,
    launcherStyle: PropTypes.instanceOf(Map),
    className: PropTypes.string
};

WidgetLayout.defaultProps = {
    onSendMessage: null,
    onToggleConversation: null,
    showChat: false,
    isCalling: false,
    toggleFullScreen: null,
    disabledInput: false,
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    launcherStyle: Map({}),
    className: ''
};

export default WidgetLayout;
