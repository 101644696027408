import React, { PureComponent } from 'react';
import Linkify from 'react-linkify';
import { MESSAGE_PROP_TYPES } from 'constants';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { buildMessageTimestamp } from '../../../../../../../../../../helpers/utils';
import Style from './style.scss';

class Message extends PureComponent {
    getSender = () => (this.props.message.get('sender') === 'client' ? 'sent' : 'received');

    render() {
        // const sanitizedHTML = marked.parse(this.props.message.get('text'), { sanitize: true });
        const { message, messageStyle } = this.props;
        return (
            <div
                className={Style[message.get('sender')]}
                style={{
                    background: messageStyle.get(this.getSender()).get('background'),
                    color: messageStyle.get(this.getSender()).get('color')
                }}
            >
                <Linkify properties={{ target: '_blank', style: { color: '#0c2e41', fontWeight: 'bold' } }}>
                    {message.get('text')}
                </Linkify>
                <span className={Style.timestamp}>{buildMessageTimestamp(message.get('timestamp'))}</span>
            </div>
        );
    }
}

Message.propTypes = {
    message: MESSAGE_PROP_TYPES.MESSAGE,
    messageStyle: PropTypes.instanceOf(Map)
};

export default Message;
