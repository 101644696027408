import actions from './types';

/** *********** SET STATE ********* */

function updateUsersDetails(userDetails) {
    return {
        type: actions.UPDATE_USERS_DETAILS,
        userDetails
    };
}

export default {
    updateUsersDetails
};
